import React, { useEffect } from 'react';
import { PAYMENT_TENDER_TYPES } from 'common/constants/SharedConstants';
import { loadScript, redirectToOrderProcessingPage, isDeliveryDisposition } from 'common/utilities/utils';
import {
  dispatchGlobalPaymentsGenerateToken,
  dispatchGlobalpaymentsInitiatePaymentAction,
  checkGlobalPayment3DsVersion,
  getSavedCardId,
  getLastUsedPaymentMethod,
  updateBillingAddressDetails,
  getPaymentProviderPlaceOrderBtn,
} from '../utils/GlobalPaymentUtils';
import { placeOrderClickHandler, dispatchOrderActions } from 'common/utilities/CheckoutUtils';
import { getPaymentOptionsTemplate } from './templates/PaymentViewTemplates';
import GlobalPaymentCreditDebitCardForm from '../Forms/GlobalPaymentCreditDebitCardForm';
import GlobalPaymentApplePayForm from '../Forms/GlobalPaymentApplePayForm';
import GlobalPaymentGooglePayForm from '../Forms/GlobalPaymentGooglePayForm';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { GLOBAL_PAYMENTS_SCRIPT_URL } from '@kfc-global/react-shared/config/config.constants';

import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';

const { GLOBAL_PAYMENTS_CONSTANTS } = PAYMENT_CONSTANTS;

const GlobalPaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;

  useEffect(() => {
    if (checkoutDetails?.paymentProvider) {
      updateCheckoutDetails({
        globalPaymentCreditCardFieldValidationStatus: false,
        globalPaymentsAccesstoken: null,
        globalPaymentsOneTimeResponse: null,
        globalPaymentCheck3DsVersion: null,
        globalPaymentAuthenticationData: null,
        enablePaymentCardInputPanel: false,
        enablePaymentProviderPlaceOrderBtn: true,
        paymentProviderPlaceOrderBtn: getPaymentProviderPlaceOrderBtn,
      });

      loadScript(config(GLOBAL_PAYMENTS_SCRIPT_URL), GLOBAL_PAYMENTS_CONSTANTS?.GLOBAL_PAYMENTS_SCRIPT_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.paymentProvider]);

  // trigger globalPaymentsGenerateToken when clicked on add payment cta button
  useEffect(() => {
    checkoutDetails?.isClickAddPayment &&
      checkoutDetails?.userParams?.loggedIn &&
      !checkoutDetails?.isClickPayOnArrival &&
      checkoutDetails?.lastUsedPaymentMethod &&
      updateCheckoutDetails({
        tender: getLastUsedPaymentMethod(checkoutDetails),
        cardid: getSavedCardId(checkoutDetails),
        billingAddressAndSaveCard: updateBillingAddressDetails(checkoutDetails),
        expiryDate: getSavedCardId(checkoutDetails) && checkoutDetails?.savedCards[0]?.expiryDate,
        nameOnCard: getSavedCardId(checkoutDetails) && checkoutDetails?.savedCards[0]?.nameOnCard,
        cardNickName: getSavedCardId(checkoutDetails) && checkoutDetails?.savedCards[0]?.cardNickName,
        isPaymentTypeSelected:
          checkoutDetails?.paymentTypeSelectedForLastUsedPayment || checkoutDetails?.isPaymentTypeSelected,
      });
    checkoutDetails?.isClickAddPayment &&
      checkoutDetails?.isRenderFromRNA &&
      checkoutDetails?.savedCards.length > 0 &&
      updateCheckoutDetails({
        placeOrderButtonEnabled: true,
      });
    (checkoutDetails?.isClickAddPayment || checkoutDetails?.isAddNewCard) &&
      dispatchGlobalPaymentsGenerateToken(checkoutDetails, updateCheckoutDetails, props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickAddPayment, checkoutDetails?.isAddNewCard]);

  /*********************************************************************
   *
   * IF enable single click payment, it will call
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.orderId &&
      checkoutDetails?.tender?.name &&
      checkoutDetails?.isPaymentTransationCompleted &&
      redirectToOrderProcessingPage(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isPaymentTransationCompleted]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  /*********************************************************************
   *
   *
   *********************************************************************/
  useEffect(() => {
    if (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.PAY_BY_CASH) {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        redirectToOrderProcessingPage(props);
    } else {
      checkoutDetails?.orderId &&
        checkoutDetails?.isCreateOrderValidateCompleted &&
        (checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD &&
        (checkoutDetails?.globalPaymentsOneTimeResponse?.paymentReference || checkoutDetails?.cardid)
          ? checkGlobalPayment3DsVersion(checkoutDetails, updateCheckoutDetails, props)
          : dispatchGlobalpaymentsInitiatePaymentAction(props, checkoutDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  useEffect(() => {
    checkoutDetails?.isClickPlaceOrder &&
      checkoutDetails?.isRenderFromRNA &&
      placeOrderClickHandler(checkoutDetails, updateCheckoutDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isClickPlaceOrder]);

  useEffect(() => {
    checkoutDetails?.dispatchOrderAction &&
      checkoutDetails?.isRenderFromRNA &&
      dispatchOrderActions({ checkoutDetails, updateCheckoutDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.dispatchOrderAction]);

  useEffect(() => {
    isDeliveryDisposition(checkoutDetails?.service) &&
      !checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected &&
      !checkoutDetails?.cardid &&
      updateCheckoutDetails({
        showBillAddressValidationError: false,
        billingAddressAndSaveCard: {
          addressLine: '',
          pincode: '',
          location: {
            latitude: '',
            longitude: '',
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected, checkoutDetails?.cardid]);

  /* Credit Card View */
  const getCreditCardUI = params =>
    window?.GlobalPayments &&
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD && (
      <GlobalPaymentCreditDebitCardForm {...props} />
    );

  return (
    <>
      {/* Payment view */}
      {window?.GlobalPayments && getPaymentOptionsTemplate(props, getCreditCardUI)}

      {/* Hidden Forms */}
      {window?.GlobalPayments && (
        <div className='hidden'>
          {/* Saved Card payment Not for New Credit card */}
          {checkoutDetails?.userParams?.loggedIn && checkoutDetails?.savedCards?.length && checkoutDetails?.cardid && (
            <GlobalPaymentCreditDebitCardForm {...props} isForSavedCardPayment='true' />
          )}
          {/* apple pay */}
          {checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.APPLE_PAY && (
            <GlobalPaymentApplePayForm {...props} />
          )}
          {/* Google Payment */}
          {checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GOOGLE_PAY && (
            <GlobalPaymentGooglePayForm {...props} />
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(GlobalPaymentView);

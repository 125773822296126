import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import {
  getPrice,
  existsInCategory,
  existsInSubCategory,
  filterNotHiddenItem,
  getDataLayer,
  getEmbeddedErrorMessageAnalytic,
  getTernaryValue,
  getAndCheck,
  getAndCheckIfOneTrue,
  isEmptyObject,
  capitalizeDispositionType,
} from 'common/utilities/utils';
import events from '@kfc-global/react-shared/analytics/Register';
import { scroller } from 'react-scroll';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import ORDER_HISTORY from 'organisms/AccountPage/Constants/Constants';
import { TURNED_NO_RESULTS_TEXT, YOUR_SEARCH_TEXT, MENU_PLP_CONSTANT } from '../MenuPLPConstants/MenuPLPConstants';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import {
  ANALYTICS_SHARED_CONSTANTS,
  ROUTE_URL,
  TO_CATERING_NAV,
  TO_MENU_NAV,
  LOCAL_STORAGE_KEYS,
  CATERING,
  MENU,
  SESSION_STORAGE_KEYS,
  DATE_FORMATS,
} from 'common/constants/SharedConstants';
import { checkIfCateringPage, ifCateringNavigationHappened } from 'common/utilities/CateringPLPUtils';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { getEpochTime, getCurrentDateTimeInUnix } from 'common/utilities/dayJsUtils';
/**
 * Method to set analytics items in the data layer object
 */
export const menuNavigationAnalyticsData = async (categoryName, menuIndex) => {
  await AnalyticsService.registerEvent(
    events?.menuNavigation?.name,
    {
      '%event%': 'menuNavigation',
      '%menuNavigationLink%': categoryName,
      '%menuIndex%': menuIndex,
    },
    getDataLayer,
  );
};
export const setAnalyticsData = async (analyticsData, field) => {
  const analyticsItems = [];
  analyticsItems.push({
    event: analyticsData.eventType,
    [field]: analyticsData.selectedData,
  });

  await AnalyticsService.registerEvent(
    'suggestedSearchClick',
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};

export const setAnalyticsDataSearchPlp = async (analyticsData, field1, field2) => {
  const analyticsItems = [];
  analyticsItems.push({
    event: analyticsData.eventType,
    [field1]: analyticsData.selectedData[field1],
    [field2]: `${analyticsData.selectedData[field2]}`,
  });

  await AnalyticsService.registerEvent(
    analyticsData.eventType,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};

export const setAnalyticsDataFilter = async (analyticsData, field1, field2, field3) => {
  const analyticsItems = [];
  analyticsItems.push({
    event: analyticsData.eventType,
    [field1]: analyticsData.selectedData[field1],
    [field2]: analyticsData.selectedData[field2],
    [field3]: analyticsData.selectedData[field3],
  });

  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};

export const filterRecommendations = (products, pastOrdersData) => {
  const dataTemp = JSON.parse(JSON.stringify(products));
  let recommendProdArr = [];
  pastOrdersData?.length &&
    pastOrdersData.forEach(obj => {
      let recommendProd = dataTemp?.find(
        ({ isHidden = false, items }) => !isHidden && items?.length && items[0].id === obj.id,
      );
      if (recommendProd) {
        recommendProdArr.push(recommendProd);
      }
    });

  return recommendProdArr;
};

export const getAnalytictsPayloadConditionaly = (selectedCategory, selectedSubcategory, name, id, currencyFactor) => {
  let productList = [];
  if (
    selectedCategory?.categories &&
    selectedCategory?.products?.length === 0 &&
    Object.keys(selectedSubcategory).length === 0
  ) {
    productList = getAnalytictsPayload(selectedCategory?.categories[0], name, id, currencyFactor);
  } else if (Object.keys(selectedSubcategory).length > 0) {
    productList = getAnalytictsPayload(selectedSubcategory, name, id, currencyFactor);
  } else {
    productList = getAnalytictsPayload(selectedCategory, name, id, currencyFactor);
  }
  return productList;
};
export const getAnalytictsPayload = (category, name, id, currencyFactor) => {
  let productDetails = {};
  let productList = [];
  productList = category?.products?.map(subItem => {
    subItem.items.forEach((element, index) => {
      let numricPrice = getPrice(element?.availability?.[0], currencyFactor).toFixed(2);
      numricPrice = numricPrice ? parseFloat(numricPrice) : 'NA';

      productDetails = {
        item_name: element.name,
        item_id: element.id,
        price: numricPrice,
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: index,
        quantity: '1',
        currency: category?.currencyShortName,
      };
    });
    return productDetails;
  });
  return productList;
};

export const vieItemListAnalytics = async (productList, dispositionType) => {
  await AnalyticsService.registerEvent(
    events?.viewItemListPlp?.name,
    {
      data: {
        event: 'view_item_list',
        disposition: capitalizeDispositionType(dispositionType),
        ecommerce: {
          item_list_id: productList?.[0]?.item_list_id,
          item_list_name: productList?.[0]?.item_list_name,
          items: productList,
        },
      },
    },
    getDataLayer,
  );
};

export const getCategoryFromSubCategory = (menu, activeMenuSelection) => {
  let selectedSubcategory = {};
  let selectedCategory = menu.find(item => item?.name?.trim() === activeMenuSelection) || {};
  if (Object.keys(selectedCategory).length === 0) {
    selectedCategory = menu.find(item => {
      if (item.categories?.length > 0) {
        for (const key in item.categories) {
          if (item.categories[key].id === activeMenuSelection) {
            selectedSubcategory = item.categories[key];
            return true;
          }
        }
      }
      return false;
    });
  }
  return { selectedCategory, selectedSubcategory };
};
export const scrollMenuItemMobile = (applicableCoupons, categoryToScroll) => {
  let offsettop = applicableCoupons?.length ? 515 : 215;
  let scrollY = +document.getElementById(categoryToScroll)?.offsetTop + offsettop;
  scrollY += document.getElementById('startorder-container')?.clientHeight || 0;
  window.scrollTo({
    top: scrollY,
    behavior: 'smooth',
  });
};
const scrollMenuItem = ({
  categories,
  category_name,
  product_name,
  categoryToScroll,
  setParamValue,
  setCategoryStatus,
  isMobile,
  applicableCoupons,
  history,
  onClickScroll,
  localized,
}) => {
  const localizedSection = document?.getElementById('startorder-container');
  const mobileLocalizedOffset = -110 - localizedSection?.clientHeight || 0;
  const offSetTopFirstCategory = getTernaryValue(applicableCoupons?.length, mobileLocalizedOffset, -100);
  const offSetTopOtherCategory = mobileLocalizedOffset;
  if (categoryToScroll) {
    const firstCategoryCheck = categories[0]?.url?.toLowerCase() === category_name?.toLowerCase();
    const offsettop = getTernaryValue(
      isMobile,
      getTernaryValue(firstCategoryCheck, offSetTopFirstCategory, offSetTopOtherCategory),
      -129,
    );
    isMobile
      ? setTimeout(() => {
          scroller.scrollTo(categoryToScroll, {
            duration: 1500,
            delay: 100,
            containerId: '',
            offset: offsettop,
          });
        }, 1000)
      : scroller.scrollTo(categoryToScroll, {
          duration: 1500,
          delay: 100,
          containerId: '',
          offset: offsettop,
        });
  } else {
    // Error messages to show :Deeplink
    if (getAndCheckIfOneTrue(category_name, product_name)) {
      const availableValue = getTernaryValue(category_name, 'category', 'item'); // 'product';
      setParamValue(availableValue);
      setCategoryStatus(true);
    }
    if (category_name) {
      setParamValue('category');
      setCategoryStatus(true);
    }
    if (getAndCheck(window.location.pathname !== ROUTE_URL.MENU_PAGE, categories?.length)) {
      let filteredCategories = filterNotHiddenItem(categories);
      filteredCategories.length &&
        window.location.pathname !== ROUTE_URL?.EVENT_PAGE &&
        scroller.scrollTo(filteredCategories?.[0]?.name?.trim(), {
          duration: 1500,
          delay: 100,
          smooth: true,
          containerId: '',
          offset: getTernaryValue(isMobile, offSetTopFirstCategory, offSetTopOtherCategory),
        });
    } else {
      if (history?.location?.isStartOrderClick) {
        setTimeout(() => {
          onClickScroll();
        }, 300);
      } else {
        window.scrollTo({
          top: 5,
          behavior: 'smooth',
        });
      }
    }
  }
};

const nestedCategoryFn = ({
  categories,
  category_name,
  product_name,
  setProductId,
  setParamValue,
  setCategoryStatus,
}) => {
  // Nested catageory :Deeplink
  const nestedCategoryObject = categories?.filter(function (element) {
    return element.categories.some(function (subElement) {
      return subElement.url.toLowerCase() === category_name.toLowerCase();
    });
  });
  // To find the subCategory :Deeplink
  if (nestedCategoryObject?.length) {
    const selectedSubCategory =
      nestedCategoryObject[0]?.categories.filter(
        subCat => subCat?.url?.toLowerCase() === category_name?.toLowerCase(),
      ) || [];

    // To find the product in subcatageory :Deeplink
    const data_filter_sub = selectedSubCategory[0].products.filter(function (element) {
      return element.items.some(function (subElement, idx) {
        return subElement.url.toLowerCase() === product_name.toLowerCase();
      });
    });

    // To get the product and make the selected product first and focus :Deeplink
    if (data_filter_sub.length) {
      const itemIndex = selectedSubCategory[0].products.findIndex(item => item.id === data_filter_sub[0].id);
      selectedSubCategory[0].products.splice(
        0, // new index,
        0, // no removal
        selectedSubCategory[0].products.splice(itemIndex, 1)[0], // detach the item and return it
      );
      setProductId(data_filter_sub[0]?.items[0]?.id);
      const getElement = document.getElementById(`${data_filter_sub[0].items[0].id}`);
      if (getElement) {
        getElement.style.border = '2px solid #2E4ABD';
      }
    }
    // Error messages to show :Deeplink
    if (data_filter_sub.length === 0 && product_name) {
      setParamValue('item'); //product
      setCategoryStatus(true);
    }
  }
};

export const getCategoryAndProduct = (
  categories,
  category_name,
  product_name,
  setProductId,
  setParamValue,
  setCategoryStatus,
  isMobile,
  applicableCoupons,
  history,
  onClickScroll,
  localized,
) => {
  let categoryToScroll;
  //Filter out the category based on the url parameter received

  // To find the 1st level categories :Deeplink
  const filteredValue = categories?.filter(item => item.url?.toLowerCase() === category_name.toLowerCase());
  if (filteredValue && filteredValue.length) {
    categoryToScroll = existsInCategory(filteredValue[0], categories);

    // Find the specific product if available
    const data_filter = filteredValue[0]?.products.filter(function (element) {
      return element.items.some(function (subElement, idx) {
        return subElement.url.toLowerCase() === product_name.toLowerCase();
      });
    });

    if (data_filter.length) {
      const itemIndex = filteredValue[0]?.products.findIndex(item => item?.id === data_filter[0]?.id);
      filteredValue[0].products.splice(
        0, // new index,
        0, // no removal
        filteredValue[0].products.splice(itemIndex, 1)[0], // detach the item and return it
      );
      setProductId(data_filter[0]?.items[0]?.id);
      const getElement = document.getElementById(`${data_filter[0]?.items[0]?.id}`);
      if (getElement) {
        getElement.style.border = '2px solid #2E4ABD';
      }
    }
    // Error messages to show :Deeplink
    if (data_filter.length === 0 && product_name) {
      setParamValue('item'); //Product
      setCategoryStatus(true);
    }
  } else {
    const nestedParams = {
      categories,
      category_name,
      product_name,
      setProductId,
      setParamValue,
      setCategoryStatus,
      categoryToScroll,
    };
    nestedCategoryFn(nestedParams);
  }

  const menuParams = {
    categories,
    category_name,
    product_name,
    categoryToScroll,
    setParamValue,
    setCategoryStatus,
    isMobile,
    applicableCoupons,
    history,
    onClickScroll,
    localized,
  };
  scrollMenuItem(menuParams);
};

export const shouldDisableButton = data => {
  return data?.isStoreClosed;
};

export const menuCardBtnTextForFavAndRecommendedMenu = data => {
  const { ADD_TO_CART_OCCASSION, STORE_CLOSED_BUTTON_TEXT } = ORDER_HISTORY;
  let btnText = translateWithI18Next(ADD_TO_CART_OCCASSION);
  let storeClosedBtnText = translateWithI18Next(STORE_CLOSED_BUTTON_TEXT);

  if (data?.isStoreClosed) {
    btnText = storeClosedBtnText;
  }

  return {
    btnText,
  };
};

export const menuNavigation = (categoryName, url, history) => {
  history.push({
    pathname: getTernaryValue(
      checkIfCateringPage(history?.location?.pathname),
      `${ROUTE_URL.CATERING_PAGE}/${url}`,
      `${ROUTE_URL.MENU_PAGE}/${url}`,
    ),
  });
  menuNavigationAnalyticsData(categoryName, 0);
};

export const checkNavigationUrl = (pathName, url) => {
  const isEventCheck = pathName.includes('/event');
  if (isEventCheck) {
    return `${ROUTE_URL.EVENT_PAGE}/${url}`;
  } else {
    return `${ROUTE_URL.MENU_PAGE}/${url}`;
  }
};

export const setFirstCategoryActive = ({
  e,
  index,
  catergoryName,
  url,
  filterNotHiddenItem,
  categories,
  scroller,
  menuNavigationAnalyticsData,
  setActiveMenuSelection,
  history,
}) => {
  if (index === 0) {
    let filteredCategories = filterNotHiddenItem(categories);
    filteredCategories?.length &&
      scroller.scrollTo(filteredCategories[index]?.name?.trim(), {
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId: '',
        offset: 50,
      });
  }
  setActiveMenuSelection(catergoryName);
  history.push({
    pathname: getTernaryValue(
      checkIfCateringPage(history?.location?.pathname),
      `${ROUTE_URL.CATERING_PAGE}/${url}`,
      checkNavigationUrl(window.location.pathname, url),
    ),
  });
};

export const closeSearchFilterMenu = (
  filterList,
  dietaryList,
  sortByOptions,
  mobileSearchFilterMenuRef,
  setHideTopRow,
) => {
  setAnalyticsDataFilter(
    {
      eventType: 'filterItems',
      selectedData: {
        filterSelected: filterList?.length > 0 ? filterList?.join() : 'none',
        dietarySelected: dietaryList?.length > 0 ? dietaryList?.join() : 'none',
        sortSelected: sortByOptions?.find(i => i.selected)?.name ? sortByOptions?.find(i => i.selected).name : 'none',
      },
    },
    'filterSelected',
    'dietarySelected',
    'sortSelected',
  );
  mobileSearchFilterMenuRef.current.style.width = '0'; // hide filter modal
  setHideTopRow(false);
};
export const menusPlpItemAnalyticFn = (suggestedData, inputSearchValue) => {
  if (suggestedData.length === 0) {
    getEmbeddedErrorMessageAnalytic(
      document.title,
      translateWithI18Next(YOUR_SEARCH_TEXT) + ` “${inputSearchValue}” ` + translateWithI18Next(TURNED_NO_RESULTS_TEXT),
    );
  }
};

export const changeMenulistOrder = categories => {
  const updatedMenuData = [];
  categories?.forEach(item => {
    if (item?.url === TRANSLATE_MAPPING_KEY.LTO_URL) {
      return updatedMenuData.unshift(item);
    } else {
      return updatedMenuData.push(item);
    }
  });
  return updatedMenuData;
};

export const getMenuData = pathname => {
  return getTernaryValue(
    checkIfCateringPage(pathname),
    { menuTitle: translateWithI18Next(TRANSLATE_MAPPING_KEY.CATERING_TEXT), showCateringflag: true },
    { menuTitle: translateWithI18Next(MENU_PLP_CONSTANT.NAV_TITLE), showCateringflag: false },
  );
};
export const checkItemName = (name, val) => {
  name = name?.replace(/[®™]/g, '');
  return name?.toLowerCase().indexOf(val?.toLowerCase()?.toString()) !== -1;
};
export const getIsMenuNavigated = (pathname, baseCategory) => {
  return (
    (pathname?.includes(ROUTE_URL.MENU_PAGE) && baseCategory?.[0]?.url === CATERING) ||
    (pathname?.includes(ROUTE_URL.CATERING_PAGE) && baseCategory?.[0]?.url === MENU)
  );
};

export const popStateListener = (event, userParams, userOrderStateDispatch, menuNavRestricted = {}) => {
  isEmptyObject(event) && event.preventDefault();
  isEmptyObject(event) && event.stopImmediatePropagation();
  let checkMenuNavigation =
    menuNavRestricted?.flag ||
    ifCateringNavigationHappened(
      userParams.localized,
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.DISPOSITION_NEW))?.type,
      window.location.pathname,
    );
  let pathname = getTernaryValue(
    isEmptyObject(menuNavRestricted),
    menuNavRestricted.pathnameToNavigate,
    window.location.pathname,
  );
  // eslint-disable-next-line default-case
  switch (checkMenuNavigation) {
    case TO_CATERING_NAV:
      userOrderStateDispatch({
        type: START_ORDER_CONSTANTS.UPDATE_CATERING_RETAIL_MENU_ALERT,
        value: {
          flag: false,
          pathname,
          title: TRANSLATE_MAPPING_KEY.CATERING_NAVALERT_TITLE,
          contentMsg: TRANSLATE_MAPPING_KEY.CATERING_NAVALERT_SUBTITLE,
        },
      });
      !isEmptyObject(menuNavRestricted) && window.history.forward();
      break;
    case TO_MENU_NAV:
      userOrderStateDispatch({
        type: START_ORDER_CONSTANTS.UPDATE_CATERING_RETAIL_MENU_ALERT,
        value: {
          flag: true,
          pathname,
          title: TRANSLATE_MAPPING_KEY.MENU_NAVALERT_TITLE,
          contentMsg: TRANSLATE_MAPPING_KEY.MENU_NAVALERT_SUBTITLE,
        },
      });
      !isEmptyObject(menuNavRestricted) && window.history.forward();
      break;
  }
};

export const menusNavRestrictionHandler = params => {
  const { MENU_NAV_ERROR } = START_ORDER_CONSTANTS;
  const { pathname, userParams, baseCategory, history, menuNavRestricted, userOrderStateDispatch } = params;
  if (getAndCheck(pathname, userParams.localized)) {
    let isMenuNavigated = getIsMenuNavigated(pathname, baseCategory);
    if (isMenuNavigated) {
      let correctFlag = getTernaryValue(baseCategory?.[0]?.url === CATERING, TO_MENU_NAV, TO_CATERING_NAV);
      let splitPath = pathname.split('/');
      userOrderStateDispatch({
        type: MENU_NAV_ERROR,
        value: { flag: correctFlag, pathnameToNavigate: '/' + splitPath[1] },
      });
      splitPath.splice(1, 1, baseCategory?.[0]?.url);
      let pathnameToNavigate = splitPath.join('/');
      history.push(pathnameToNavigate);
    } else if (menuNavRestricted?.flag) {
      popStateListener({}, userParams, userOrderStateDispatch, menuNavRestricted);
      userOrderStateDispatch({
        type: MENU_NAV_ERROR,
        value: { flag: '', pathnameToNavigate: '' },
      });
    }
  }
};

export const saveScheduleDate = (
  fetchMenuPayload,
  serviceType,
  scheduleOrderObject = { enableScheduleOrderFlag: false, showOrderNow: false, allowScheduleOrderForDispositions: [] },
  epochTime,
) => {
  const { enableScheduleOrderFlag, showOrderNow, allowScheduleOrderForDispositions } = scheduleOrderObject;
  let menuPayload = fetchMenuPayload;
  if (enableScheduleOrderFlag && !showOrderNow && allowScheduleOrderForDispositions?.includes(serviceType)) {
    menuPayload = updateMenuPayload(fetchMenuPayload, epochTime);
  }
  if (!enableScheduleOrderFlag && allowScheduleOrderForDispositions?.includes(serviceType)) {
    menuPayload = updateMenuPayload(fetchMenuPayload, epochTime);
  }
  return menuPayload;
};

export const updateMenuPayload = (menuPayload, epochTime) => {
  let startDateInEpoch = sessionStorage.getItem(SESSION_STORAGE_KEYS.SCHEDULE_DATE_EPOCH);
  if (!startDateInEpoch) {
    const selectedTime = JSON.parse(localStorage.getItem('dateTimeOrder'))?.selectedTime;
    startDateInEpoch = selectedTime !== 'ASAP' ? epochTime : getCurrentDateTimeInUnix();
  }
  menuPayload.startDateInEpoch = +startDateInEpoch;
  return menuPayload;
};

export const getMobileScrollOffsetMenu = menuScroll => {
  const startOrderItemsSection = document?.getElementById('startorder-container');
  const localizedSectionHeight = startOrderItemsSection?.clientHeight + 120 || 0;
  const mobileScrollOffset = getTernaryValue(
    localizedSectionHeight,
    -localizedSectionHeight,
    menuScroll ? -getHeaderHeightForMobile() : -120,
  );
  return mobileScrollOffset;
};

export const getHeaderHeightForMobile = () => {
  const adHeight = document.getElementById('kfc-banner-image')?.clientHeight;
  const offSetTop =
    document.getElementById('header-container')?.clientHeight +
    document.getElementById('mob-searchinput-id')?.clientHeight;
  return adHeight ? adHeight + offSetTop - 50 : offSetTop;
};

export const getScheduleTimeFormat = (disposition, slectedDate) => {
  return disposition?.date && disposition?.time && disposition?.epochTime
    ? disposition?.epochTime
    : getEpochTime(`${slectedDate} ${disposition?.time}`, DATE_FORMATS.YYYY_MM_DD_h_mm_a);
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ButtonType, ContainedButton } from 'atoms/Buttons';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import WELCOME_BACK_COSTANTS from '../Constants/Constants';
import { UserOrderContext } from 'context/context';
import { getValueOrDefaultObject, isEmptyEvery, getStoreName, getFullAddressDetails } from 'common/utilities/utils';
import { DISPOSITIONNEW, DISPOSITION_TYPES, DELIVERY, PICK_UP } from 'common/constants/SharedConstants';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { keepOrderAnalyticFn } from 'common/utilities/analyticsUtils';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';

const WelcomeBackComponent = props => {
  const { setIsModalOpen, onCancel, analyticsData } = props;

  const { YES_KEEP_IT, ORDER_FOR, WELCOME_BACK, CHANGE_BTN, PREVIOUS_ORDER_SETTING_MSG, CHRISTMAS_ORDERING } =
    WELCOME_BACK_COSTANTS;

  const { userOrderState, userOrderStateDispatch } = useContext(UserOrderContext);
  const dispositionNew = isEmptyEvery(userOrderState?.dispositionNew)
    ? JSON.parse(localStorage.getItem(DISPOSITIONNEW))
    : userOrderState.dispositionNew;

  const { type: dispositionType = '', store, deliveryAddress = '' } = dispositionNew || {};
  const tenantReducer = useSelector(data => data.tenantReducer);
  const showStoreNameInWelcomeBackMsg = tenantReducer?.showStoreNameInWelcomeBackMsg;
  const storeName = getStoreName(getValueOrDefaultObject(store?.name));
  const {
    addressLines = '',
    city = '',
    state = '',
    pinCode = '',
  } = getFullAddressDetails(store?.addresses, false, true) ?? {};
  const service = dispositionType.toLowerCase();
  const isChristmasOrdering = service === CHRISTMAS_ORDERING;
  const onChangeSettingHandler = event => {
    setIsModalOpen(false);
    event.preventDefault();
    event.stopPropagation();
    userOrderStateDispatch({ type: START_ORDER_CONSTANTS.DISPOSITION_DATA, value: { showDisposition: true } });
    keepOrderAnalyticFn({
      keepOrder: WELCOME_BACK_COSTANTS.CHANGE,
      analyticsData: analyticsData,
    });
  };

  return (
    <div className='welcome-container'>
      <div className='welcome-back-heading'>
        <p className='mb-0'>
          {translateWithI18Next(WELCOME_BACK)}
          {'!'}
        </p>
        <p>{translateWithI18Next(PREVIOUS_ORDER_SETTING_MSG)}</p>
      </div>
      <div className='order-details-section'>
        <p className='mb-0 order-type'>
          {translateWithI18Next(ORDER_FOR)}{' '}
          {translateWithI18Next(isChristmasOrdering ? TRANSLATE_MAPPING_KEY.CHRISTMAS_ORDERING : service)}
        </p>
        {addressLines && (
          <>
            {showStoreNameInWelcomeBackMsg && <p className='mb-0'>{`${storeName}`}</p>}
            <p className='mb-0'>{`${addressLines?.join(' ')}, ${city}`}</p>
            <p>{`${state}, ${pinCode}`}</p>
          </>
        )}
        {!addressLines && dispositionType === DISPOSITION_TYPES?.DELIVERY && deliveryAddress}
      </div>
      <div className='buttonItems'>
        <ContainedButton
          data-testid='welcome-back-change-button'
          variant={ButtonType.tertiary}
          onClickHandler={onChangeSettingHandler}
        >
          {translateWithI18Next(CHANGE_BTN)}
        </ContainedButton>
        <ContainedButton data-testid='yes-keep-it-btn' variant={ButtonType.secondary} onClickHandler={onCancel}>
          {translateWithI18Next(YES_KEEP_IT)}
        </ContainedButton>
      </div>
    </div>
  );
};

export default WelcomeBackComponent;

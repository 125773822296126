import { Provider } from 'react-redux';
import { UserOrderContextProvider } from '../context/context';
import React, { useEffect } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { PersistGate } from 'redux-persist/integration/react';
import { newStore } from '@kfc-global/react-shared/redux/Store';
import { LANGUAGE } from 'common/constants/SharedConstants';
import storage from 'redux-persist/lib/storage';
import AppWrapper from './App';
import App from '@kfc-global/react-shared/App';
import { APP_SOURCE_WEB } from 'app/Constants';
import { getTranslationLanguage } from 'common/utilities/utils';
import storageSession from 'redux-persist/lib/storage/session';
import { QueryClient } from '@tanstack/react-query';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

const storageSessionReducers = [{ key: 'orderReducer', storage: storageSession }];
const { store, persistor } = newStore(
  storage,
  null,
  [
    'appConfigsReducer',
    'giftCardReducer',
    'giftCardPurchaseReducer',
    'marketConfigReducer',
    'microAppPaymentReducer',
    'paymentsGiftCardReducer',
    'paymentsGoogleSpotReducer',
    'postOrderReducer',
    'paymentsReducer',
    'pwaStoragsReducer',
  ],
  storageSessionReducers,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage,
});

const KFCApp = () => {
  const tenantCode = process?.env?.REACT_APP_TENANT_CODE;
  const appApiBaseUrl = process?.env?.REACT_APP_API_BASE_URL;
  const queryParamLanguage = new URLSearchParams(window.location.search)?.get(LANGUAGE);

  if (!tenantCode || !appApiBaseUrl) {
    throw new Error(
      'Env variables REACT_APP_TENANT_CODE or REACT_APP_API_BASE_URL is missing.' +
        ' Please provide them during build or start',
    );
  }
  console.log(`Loading Application for Tenant: ${tenantCode}`);

  useEffect(() => {
    App.appApiBaseUrl = appApiBaseUrl;
    App.channel = APP_SOURCE_WEB;
    App.language = !queryParamLanguage
      ? getTranslationLanguage()
      : getTranslationLanguage({ language: queryParamLanguage });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UserOrderContextProvider>
              <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: asyncStoragePersister }}>
                <AppWrapper />
              </PersistQueryClientProvider>
            </UserOrderContextProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default KFCApp;

import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { getDurationWithDiffInHours } from 'common/utilities/dayJsUtils';
import { getDataLayer, isEmptyArray } from 'common/utilities/utils';
import { CART_PAGE_CONSTANTS } from '../Constants/CartPageConstants';

export const setAnalyticsData = async (event, cartItems, categories = []) => {
  const { ANALYTICS_EVENTS } = CART_PAGE_CONSTANTS;

  if (event === ANALYTICS_EVENTS.EDIT) {
    await AnalyticsService.registerEvent(
      event,
      {
        '%event%': 'editCartItem',
      },
      getDataLayer,
    );
    return;
  }

  const items = cartItems.map(async (item, index) => {
    const { id = 'NA', name = 'NA' } = categories?.find(cat => cat.products.find(p => p.name === item.foodName)) || {};
    return {
      item_name: item?.foodName || 'NA',
      item_id: `${item?.foodItemId || 'NA'}`,
      price: item?.unitPrice || 'NA',
      item_category: name,
      item_variant: isEmptyArray(item?.modifiers) ? 'NA' : item?.modifiers?.reduce((acc, cv) => acc + `${cv}, `, ''),
      item_list_name: name,
      item_list_id: id,
      index: index,
      quantity: `${item?.quantity || 'NA'}`,
    };
  });

  await AnalyticsService.registerEvent(
    event,
    {
      data: {
        event,
        ecommerce: {
          [event]: {
            items,
          },
        },
      },
    },
    getDataLayer,
  );
};

export const isCartExpired = userLastActiveTime => {
  if (!userLastActiveTime) {
    return false;
  }
  const duration = getDurationWithDiffInHours(userLastActiveTime);
  return duration > CART_PAGE_CONSTANTS.CART_EXPIRE_TIME_HRS;
};

const PAYMENT_CONSTANTS = {
  SELECT_YOUR_PAYMENT: 'SELECT YOUR PAYMENT',

  BRAINTREE_PAYMENT_TYPE: {
    KEY_CARD: 'card',
    KEY_CREDIT_CARD: 'CreditCard',
    KEY_PAY_PAL: 'paypal',
    KEY_PAY_PAL_ACCOUNT: 'PayPalAccount',
    KEY_PAY_PAL_CREDIT: 'paypalCredit',
    KEY_GOOGLE_PAY: 'googlePay',
    KEY_ANDROID_PAY_CARD: 'AndroidPayCard',
    KEY_APPLE_PAY: 'applePay',
    KEY_APPLE_PAY_CARD: 'ApplePayCard',
    KEY_VENMO_ACCOUNT: 'VenmoAccount',
  },
  BRAINTREE_EVENT: {
    CHANGE_ACTIVE_VIEW: 'changeActiveView',
    PAYMENT_METHOD_REQUESTABLE: 'paymentMethodRequestable',
    NO_PAYMENT_METHOD_REQUESTABLE: 'noPaymentMethodRequestable',
    PAYMENT_OPTION_SELECTED: 'paymentOptionSelected',
  },
  BRAINTREE_CHECKOUT: 'checkout',
  BRAINTREE_VAULT: 'vault',
  BRAINTREE_FINAL: 'final',

  GLOBAL_PAYMENTS_CONSTANTS: {
    YES: 'YES',
    ENROLLED: 'ENROLLED',
    NOT_ENROLLED: 'NOT_ENROLLED',
    SUCCESS_AUTHENTICATED: 'SUCCESS_AUTHENTICATED',
    SUCCESS_ATTEMPT_MADE: 'SUCCESS_ATTEMPT_MADE',
    CHALLENGE_REQUIRED: 'CHALLENGE_REQUIRED',
    GLOBAL_PAYMENTS_3DS_SCRIPT_ID: 'globalPayment3dsScriptId',
    GLOBAL_PAYMENTS_SCRIPT_ID: 'globalPaymentScriptId',
    GLOBAL_PAYMENTS_SANDBOX: 'sandbox',
    GLOBAL_PAYMENTS_PRODUCTION: 'production',
    FAILED: 'FAILED',
  },

  GLOBAL_PAYMENT_APPLE_PAY_CONFIGURATION_CONSTANTS: {
    API_VERSION: '2021-03-22',
    ALLOWED_CARD_NETWORKS: ['VISA', 'MASTERCARD', 'AMEX'],
    APPLE_PAY_VERSION_NUMBER: 3,
    BUTTON_STYLE_COLOR: 'black',
    BUTTON_TYPE: 'order',
    MERCHANT_CAPABILITIES: ['supports3DS'],
  },

  GLOBAL_PAYMENT_GOOGLE_PAY_CONFIGURATION_CONSTANTS: {
    API_VERSION: '2021-03-22',
    ALLOWED_CARD_NETWORKS: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER'],
    ALLOWED_AUTH_METHODS: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    ALLOWED_GOOGLEPAY_CARD_NETWORKS: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'MIR', 'VISA'],
    BUTTON_STYLE_COLOR: 'black',
    BUTTON_TYPE: 'order',
  },

  GLOBAL_PAYMENTS_ADDRESS_VALIDTAION_PAYLOAD_CONSTANTS: {
    ACCOUNT_NAME: 'transaction_processing',
    CHANNEL: 'CNP',
    CAPTURE_MODE: 'AUTO',
    TYPE: 'SALE',
    ENTRY_MODE: 'ECOM',
    CVV_INDICATOR: 'PRESENT',
  },

  GLOBAL_PAYMENTS_BROWSER_DATA_VALUES: {
    COLOR_DEPTH: 0,
    ACCEPT_HEADER: 'text/html,application/xhtml+xml,application/xml;q=9,image/webp,img/apng,*/*;q=0.8',
  },

  PAYMENT_FAILED_SCREEN_TEXT: {
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    BUTTON_TEXT: 'ButtonText',
  },
  PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES: {
    CARD_NUMBER: 'cardNumber',
    CARD_NUMBER_TITLE: 'cardNumberTitle',
    CARD_NUMBER_INPUT_PATTERN: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CARD_NUMBER_VALIDATION_REGEX: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CVV: 'cardCVV',
    CARD_NUMBER_CVC: 'cvc',
    CVV_INPUT_PATTERN: '^([0-9]{3})$',
    CVV_VALIDATION_REGEX: /^([0-9]{3})$/,
    CREDITCARD_CVV_INPUT_PATTERN: !/^\d{3,4}$/,
    EXPIRY_DATE: 'cardExpDate',
    CARD_NUMBER_EXPIRY: 'expiryDate',
    CARD_EXPIRY_DATE_INPUT_PATTERN: '^(([0][1-9]|[1][0-2])/[2][0-9][0-9][0-9])$',
    CARD_EXPIRY_DATE_VALIDATION_REGEX: /^(([0][1-9]|[1][0-2])\/[2][0-9][0-9][0-9])$/,
    NAME_ON_CARD: 'cardHolderName',
    NAME_ON_CARD_INPUT_PATTERN: '',
    NAME_ON_CARD_VALIDATION_REGEX: /^([a-zA-Z]{1,})([ \-']{0,1}[a-zA-Z]+){0,2}$/,
    DELIVERY_ADDRESS_CHECKBOX: 'deliveryAddressCheckbox',
    CHECKBOX: 'checkbox',
    SAVECARD: 'saveCard',
    CARD_NICK_NAME: 'nickName',
    CARD_NICK_NAME_INPUT_PATTERN: '^([a-zA-Z ]{50})$',
    BILLING_ADDRESS: 'billingAddress',
    BILLING_ADDRESS_TITLE: 'billingAddressTitle',
    BILLING_ADDRESS_INPUT_PATTERN: '^([a-zA-Z 0-9]{250})$',
    POSTAL_CODE: 'postalCode',
    POSTAL_CODE_INPUT_PATTERN: '^([a-zA-Z 0-9]{20})$',
    ADDRESS: 'address',
    CREDIT_OR_DEBIT_CARD_TYPE: 'creditOrDebitCard',
  },
  DGFT_PAYMENT_CREDIT_DEBIT_CARD_VIEW_TEMPLATES: {
    CARD_NUMBER: 'cardNumber',
    CARD_NUMBER_TITLE: 'cardNumberTitle',
    CARD_NUMBER_INPUT_PATTERN: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CARD_NUMBER_VALIDATION_REGEX: /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/,
    CVV: 'cardCVV',
    CARD_NUMBER_CVC: 'cvc',
    CVV_INPUT_PATTERN: '^([0-9]{3})$',
    CVV_VALIDATION_REGEX: /^([0-9]{3})$/,
    CREDITCARD_CVV_INPUT_PATTERN: !/^\d{3,4}$/,
    EXPIRY_DATE: 'cardExpDate',
    CARD_NUMBER_EXPIRY: 'expiryDate',
    CARD_EXPIRY_DATE_INPUT_PATTERN: '^(([0][1-9]|[1][0-2])/[0-9][0-9])$',
    CARD_EXPIRY_DATE_VALIDATION_REGEX: /^(([0][1-9]|[1][0-2])\/[0-9][0-9])$/,
    NAME_ON_CARD: 'cardHolderName',
    NAME_ON_CARD_INPUT_PATTERN: '',
    NAME_ON_CARD_VALIDATION_REGEX: /^([a-zA-Z]{1,})([ \-']{0,1}[a-zA-Z]+){0,2}$/,
    DELIVERY_ADDRESS_CHECKBOX: 'deliveryAddressCheckbox',
    CHECKBOX: 'checkbox',
    SAVECARD: 'saveCard',
    CREDIT_OR_DEBIT_CARD_TYPE: 'creditOrDebitCard',
  },
  RETURN_TO_PAYMENT_OPTION_CHECKOUT: 'RETURN_TO_PAYMENT_OPTION_CHECKOUT',
  MULTIPLE: 'multiple',
  SINGLE: 'single',
  GLOBAL_PAYMENTS_VERIFICATION_API_RESULT: {
    VERIFIED: 'VERIFIED',
    MATCHED: 'MATCHED',
    VERIFY: 'VERIFY',
    SUCCESS: 'SUCCESS',
  },
  GLOBAL_PAYMENTS_VERIFICATION_ERROR_CODE: {
    OSPS019: 'OSPS019',
    OSPS020: 'OSPS020',
    OSPS022: 'OSPS022',
  },
  GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS: {
    ADDRESS_LINE: 'addressLine',
    PINCODE: 'pincode',
  },
  DGFT_APPLE_PAY_CONSTANTS: {
    ALLOWED_CARD_NETWORKS: ['visa', 'masterCard', 'amex', 'discover'],
    MERCHANT_CAPABILITIES: ['supports3DS'],
    APPLEPAY: 'Applepay',
    STATUS_SUCCESS: 0,
    STATUS_FAILURE: 1,
    APPLE_PAY_VERSION_NUMBER: 3,
  },

  TRUE_MONEY_WALLET_CONSTANTS: {
    MOBILE_NUMBER: 'mobileNumber',
    MOBILE_NUMBER_REGX: /\(?((06|08|09){1}[0-9]{1})\)?[ ]?\(?([0-9]{3})\)?[ ]?\(?([0-9]{4})\)?/,
    OTP_VALIDATION_WRONG_NUMBER_ERROR_CODE: 'OSBR072',
    OTP_VALIDATION_ACCOUNT_ERROR: 'OSBR074',
    MOBILE_NUMBER_MAX_LENGTH: '12',
    OTP_INVALID: 'OSBR079',
    INSUFFICIENT_BALANCE: 'OSBR096',
    ERROR_TRANSACTION_CALL: 'OSBR102',
    PHONE_NUMBER_MASK: '999 999 9999',
  },
  PAYMENT_STORAGE: {
    CHECKOUT_DETAILS: 'checkoutDetailsStore',
  },
  ADYEN_PAYMENTS_VERIFICATION_API_RESULT: {
    AUTHORISED: 'Authorised',
  },
  ADYEN_PAYMENT_TYPE: {
    ADYEN: 'adyen',
    PAYMENT_OPTIONS: 'Payment Options',
    CONTINUE_ORDERING: 'Continue Ordering',
    PAYMENT_TYPE: 'scheme',
    TIMEZONE_OFFSET: '0',
    CREDIT_OR_DEBIT: 'Credit or debit card',
    ADYEN_PAYMENTS_PRODUCTION: 'production',
    ADYEN_PAYMENTS_TEST: 'test',
  },

  ADDRESS_VERIFICATION_REGX:
    /^[a-zA-ZÀ-ÿŒœ|0-9\p{L}\p{M}\p{N}.\-_'’,&#]+(?: [a-zA-ZÀ-ÿŒœ|0-9\p{L}\p{M}\p{N}.\-_'’,&#]+)*$/,
};

export default PAYMENT_CONSTANTS;

import React from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import { createPayloadForStatusCall, getDataLayer } from 'common/utilities/utils';
import DELIVERY_ADDRESS_SEARCH_FORM_CONSTANTS from '../Constants/DeliveryAddressSearchFormConstants';
import { Config, UBER_DIRECT } from 'common/constants/SharedConstants';
import { login } from 'common/utilities/LoginUtils';
import Strings from '@kfc-global/react-shared/constants/Strings';
import { AnchorWithLink } from '../../../atoms/Anchor';
import { getBulkStoreStatus } from '@kfc-global/react-shared/redux/Actions/StoresAction';
import START_ORDER_CONSTANTS from '../../StartOrderComponent/Constants/startOrderConstants';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
const { ANALYTICS_LOCALISATION_SIGNIN } = START_ORDER_CONSTANTS;
const {
  ADDRESS_LABEL,
  APARTMENT_NAME,
  CHOME,
  CITY_TOWN,
  COMPANY_NAME,
  POSTAL_CODE,
  PREFECTURE,
  ROOM_NUMBER,
  SPECIAL_NOTES,
  STREET_ADDRESS_DETAILS,
} = DELIVERY_ADDRESS_SEARCH_FORM_CONSTANTS;

/**
 * check whether the store has delivery partner or self-delivery
 */
export const isDeliveryPartner = deliveryPartner => (deliveryPartner === UBER_DIRECT ? true : false);

/**
 * selectedAddressDefault - default values object for delivery address form
 */
export const selectedAddressDefault = {
  addressName: '',
  apartmentName: '',
  chome: '',
  cityTown: '',
  companyName: '',
  deliverySearchType: Strings.postalCode,
  postalCode: '',
  prefecture: '',
  roomNumber: '',
  specialNote: '',
  streetDetailAddress: '',
};

/**
 * getUniqueAddrName - unique value for address nick name
 */
export const getUniqueAddrName = (nameArray, name) => {
  let string = name;
  if (nameArray.includes(string)) {
    string = string.replace(/(\d+)+/g, (match, number) => parseInt(number) + 1);
    return getUniqueAddrName(nameArray, string);
  } else {
    return string;
  }
};
/**
 * getAddressValue - default value address nick name
 */
export const getAddressValue = savedAddresses => {
  if (!savedAddresses) {
    return '';
  }
  let addrValue = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADDRESS)} 1`;
  const addressNameArray = savedAddresses?.map(addr => addr.name);
  const nameArray = [...addressNameArray].filter(
    addr => addr.indexOf(translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADDRESS)) > -1,
  );
  return getUniqueAddrName(nameArray, addrValue);
};

/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = async analyticsData => {
  const analyticsItems = [];
  analyticsItems.push(analyticsData);
  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};

export const setLocalisedSignInAnalyticsData = async () => {
  await AnalyticsService.registerEvent(
    events?.localisationSignIn?.name,
    {
      '%event%': 'localisationSignIn',
    },
    getDataLayer,
  );
};

/**
 * getDeliveryAddressSearchTypes - returns an array of delivery search types
 * Used for setting the selected tab and overall form type
 */
export function getDeliveryAddressSearchTypes() {
  const addressDeliverySearchType = {
    id: Strings.address,
    title: ADDRESS_LABEL,
    fieldOrder: [
      {
        id: Strings.prefecture,
        title: PREFECTURE,
      },
      {
        id: Strings.cityTown,
        title: CITY_TOWN,
      },
      {
        id: Strings.chome,
        title: CHOME,
      },
    ],
  };
  const postalCodeDeliverySearchType = {
    id: Strings.postalCode,
    title: POSTAL_CODE,
    fieldOrder: [
      {
        id: Strings.postalCode,
        title: POSTAL_CODE,
      },
      {
        id: Strings.chome,
        title: CHOME,
      },
    ],
  };
  return [postalCodeDeliverySearchType, addressDeliverySearchType];
}

/**
 * getDeliveryAddressTextFields - returns an array of text fields
 * Used for setting the input text fields required for delivery address forms
 */
export function getDeliveryAddressTextFields() {
  return [
    {
      id: STREET_ADDRESS_DETAILS,
      title: STREET_ADDRESS_DETAILS,
      required: true,
      maxLegth: 12,
    },
    {
      id: APARTMENT_NAME,
      title: APARTMENT_NAME,
      required: false,
      maxLegth: 24,
    },
    {
      id: ROOM_NUMBER,
      title: ROOM_NUMBER,
      required: false,
      maxLegth: 12,
    },
    {
      id: COMPANY_NAME,
      title: COMPANY_NAME,
      required: false,
      maxLegth: 12,
    },
    {
      id: SPECIAL_NOTES,
      title: SPECIAL_NOTES,
      required: false,
      maxLegth: 100,
    },
  ];
}

/**
 * callStatusAPI - calls the bulk store status api endpoint
 * @param storeList
 * @param serviceType
 * @param dispatch
 * @param tenantId
 */
export const callStatusAPI = (storeList, serviceType, dispatch, tenantId) => {
  const dataList = createPayloadForStatusCall(storeList, serviceType, null, true);
  const statusCallPayload = {
    data: dataList,
    store: storeList,
    tenantId,
    setStatusFlag: false,
    isFavoritePage: false,
  };
  dispatch(getBulkStoreStatus(statusCallPayload));
};

export const signInClickHandler = event => {
  event.stopPropagation();
  event.preventDefault();
  localStorage.setItem(ANALYTICS_LOCALISATION_SIGNIN, JSON.stringify(true));
  setLocalisedSignInAnalyticsData();
  login();
};

export const showLoginLinkForNonLoggedInUsers = (memberText, setSignInClickHandler, signInLink) => {
  return (
    <div data-id={Config.HEADER_SIGN_IN_ATTR_DATA_ID}>
      {translateWithI18Next(memberText)}
      <span onClick={setSignInClickHandler}>
        <AnchorWithLink targetType={signInLink.targetType} href={signInLink.linkUrl} className='delivery-signin-link'>
          {translateWithI18Next(signInLink.linkText)}
        </AnchorWithLink>
      </span>
    </div>
  );
};

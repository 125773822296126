import React, { useEffect } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib/i18N';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import {
  initiateGlobalPayment3DsAuthentication,
  dispatchGlobalpaymentsInitiatePaymentAction,
  getOneTimeGlobalPaymentToken,
  updateCreditCardValidationsStauts,
} from '../utils/GlobalPaymentUtils';
import { redirectToPaymentFailurePage, isDeliveryDisposition } from 'common/utilities/utils';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { getBillingOrShippingAddressJSX } from 'organisms/Payment/presentation/templates/PaymentViewTemplates';
import CONTEXT_REDUCER_MAPPING_KEY from 'common/constants/ContextReducerKeys';
import { paymentTrancationErrorHandler } from 'common/utilities/PaymentUtils';

const getCheckooutDetails = checkoutDetailsVal =>
  `cc-and-dc-form ${!checkoutDetailsVal?.isAddNewCard ? 'cc-and-dc-form-checkout' : ''} `;

const getGlobalPaymentCreditCardForm = () => (
  <form id='global-payment-form' data-testid='global-payment-form' method='post' action='#'>
    <div id='credit-card'>
      <label for='card-holder-name'></label>
      <div id='card-holder-name'></div>
      <label for='card-number'></label>
      <div id='card-number'></div>
      <label for='card-expiration'></label>
      <div id='card-expiration'></div>
      <label for='card-cvv'></label>
      <div id='card-cvv'></div>
    </div>
    <div id='submitGPFrom'></div>
  </form>
);

const GlobalPaymentCreditDebitCardForm = props => {
  const { checkoutDetails, updateCheckoutDetails, isForSavedCardPayment = false } = props;
  const { GLOBAL_PAYMENTS_CONSTANTS } = PAYMENT_CONSTANTS;

  useEffect(() => {
    !isForSavedCardPayment && getOneTimeGlobalPaymentToken(props);
    updateCheckoutDetails({
      globalPaymentPlaceOrderClick: false,
      showBillAddressValidationError: false,
      billingAddressAndSaveCard: {
        ...checkoutDetails?.billingAddressAndSaveCard,
        defaultAddressSelected: isDeliveryDisposition(checkoutDetails?.service),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isForSavedCardPayment && checkoutDetails?.isClickPlaceOrder) {
      checkoutDetails?.userOrderStateDispatch?.({
        type: CONTEXT_REDUCER_MAPPING_KEY?.SHOW_BUCKET_LOADER,
        value: true,
      });
    }
  }, [checkoutDetails, isForSavedCardPayment]);

  useEffect(() => {
    checkoutDetails?.globalPaymentCreditCardFieldValidationStatus &&
      updateCreditCardValidationsStauts({ checkoutDetails, updateCheckoutDetails, props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentCreditCardFieldValidationStatus]);

  useEffect(() => {
    // more than 5 Seconds Loder should stop.
    checkoutDetails?.globalPaymentPlaceOrderClick
      ? updateCheckoutDetails({
          loderSetTimoutInstance: setTimeout(() => {
            updateCreditCardValidationsStauts({
              checkoutDetails,
              updateCheckoutDetails,
              props,
              setPlaceOrderClick: true,
            });
            clearTimeout(checkoutDetails?.loderSetTimoutInstance);
          }, 5000),
        })
      : clearTimeout(checkoutDetails?.loderSetTimoutInstance);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentPlaceOrderClick]);

  useEffect(() => {
    if (checkoutDetails?.globalPaymentCheck3DsVersion?.serverTransactionId) {
      // Non-3ds
      checkoutDetails?.globalPaymentCheck3DsVersion?.enrolled === GLOBAL_PAYMENTS_CONSTANTS?.NOT_ENROLLED &&
        checkoutDetails?.globalPaymentCheck3DsVersion?.liabilityShift !== GLOBAL_PAYMENTS_CONSTANTS?.YES &&
        dispatchGlobalpaymentsInitiatePaymentAction(props, checkoutDetails);
      // 3ds
      checkoutDetails?.globalPaymentCheck3DsVersion?.enrolled === GLOBAL_PAYMENTS_CONSTANTS?.ENROLLED &&
        initiateGlobalPayment3DsAuthentication(checkoutDetails, updateCheckoutDetails, props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentCheck3DsVersion?.serverTransactionId]);

  useEffect(() => {
    //  3ds frictionless
    (checkoutDetails?.globalPaymentAuthenticationData?.result === GLOBAL_PAYMENTS_CONSTANTS?.SUCCESS_AUTHENTICATED ||
      checkoutDetails?.globalPaymentAuthenticationData?.result === GLOBAL_PAYMENTS_CONSTANTS?.SUCCESS_ATTEMPT_MADE) &&
      dispatchGlobalpaymentsInitiatePaymentAction(props, checkoutDetails);

    checkoutDetails?.globalPaymentAuthenticationData?.result === GLOBAL_PAYMENTS_CONSTANTS?.FAILED &&
      paymentTrancationErrorHandler({ ...props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentAuthenticationData?.result]);

  useEffect(() => {
    //  3ds challenge flow
    checkoutDetails?.globalPaymentAuthenticationData?.status === GLOBAL_PAYMENTS_CONSTANTS?.CHALLENGE_REQUIRED &&
      dispatchGlobalpaymentsInitiatePaymentAction(props, checkoutDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentAuthenticationData?.status]);

  useEffect(() => {
    (checkoutDetails?.globalPaymentAuthenticationData?.errors ||
      checkoutDetails?.globalPaymentCheck3DsVersion?.errors) &&
      redirectToPaymentFailurePage({ ...props });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.globalPaymentAuthenticationData?.errors, checkoutDetails?.globalPaymentCheck3DsVersion?.errors]);

  return (
    !isForSavedCardPayment && (
      <div className={getCheckooutDetails(checkoutDetails)}>
        <div className='row row-cols-12 '>
          <div className={`${checkoutDetails?.isAddNewCard ? 'col-sm-4' : 'col-sm-12'} col-12`}>
            <div className={`labelContent ${checkoutDetails?.isAddNewCard ? 'labelContentAddNewCard' : ''} `}>
              <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.CREDIT_CARD_DETAILS)}</span>
            </div>
          </div>
          <div className={`${checkoutDetails?.isAddNewCard ? 'col-sm-8' : 'col-sm-12'} col-12`}>
            {getGlobalPaymentCreditCardForm()}
          </div>
        </div>
        {getBillingOrShippingAddressJSX(props)}
      </div>
    )
  );
};

export default React.memo(GlobalPaymentCreditDebitCardForm);

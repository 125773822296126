import {
  PAYMENT_STATUS,
  PAYMENT_TENDER_TYPES,
  DOCUMENT_KEYS,
  VALID,
  LANGUAGE_KEYS,
  KBANK_QR_CODE_FORM,
} from 'common/constants/SharedConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib/i18N';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import {
  getKbankConfiguration,
  kbankChargePayment,
  kbankGetSavedCards,
  deleteKbankSavedCards,
  trueMoneyWalletGenetateOtp,
  fetchKbankQROrderID,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { updateClickPlaceOrderState } from 'common/utilities/PaymentUtils';
import { loadScript, redirectToPaymentFailurePage, toastifyFn, toastifyFnWithImage } from 'common/utilities/utils';
import ImageComponent from 'atoms/Image';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { KBANK_PAYMENTS_CONFIG_SCRIPT_URL } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { getLanguage } from '@kfc-global/kfc-i18n/lib';
const { TRUE_MONEY_WALLET_CONSTANTS } = PAYMENT_CONSTANTS;
const { OTP_VALIDATION_ACCOUNT_ERROR } = TRUE_MONEY_WALLET_CONSTANTS;

export const KbankLoadFunction = fn => {
  if (document.readyState !== DOCUMENT_KEYS.LOADING) {
    fn();
  } else {
    document.addEventListener(DOCUMENT_KEYS.DOM_CONTENT_LOADED, fn);
  }
};

export const kbankToken = (event, props) => {
  const kinlinecheckout = window.KInlineCheckout;
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { billingAddressAndSaveCard } = checkoutDetails;
  event.preventDefault();
  kinlinecheckout
    .getToken()
    .then(function (response) {
      updateCheckoutDetails({
        KbankToken: response?.token,
        billingAddressAndSaveCard: {
          ...billingAddressAndSaveCard,
          enabledSaveCard: response?.saveCard,
        },
      });
      updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
    })
    .catch(function (e) {
      console.log('Some error has occurred in getting token', e);
    });
};

// function to get apikey for KBANK credit card payment inline script
export const dispatchConfigDynamicApiRequest = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch, tenantId } = checkoutDetails;
  const data = {
    orderId: checkoutDetails?.orderId,
    tenderType: checkoutDetails?.tender?.name,
  };
  dispatch(
    getKbankConfiguration(
      {
        data,
        tenantId,
      },
      response => {
        updateCheckoutDetails({ publicTokenApiKey: response?.publicTokenApiKey });
      },
      error => {
        console.log('response in error', error);
      },
    ),
  );
};

export const KbankStyleFunction = () => {
  // Customize Style Function
  let fontSetting = {
    fontColor: 'black',
  };
  let fontData = {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    fontColor: 'black',
    fontSize: '.75em',
  };
  const fontAttributes = {
    'card-name': fontSetting,
    'card-number': fontSetting,
    'card-expiry': fontSetting,
    'card-cvv': fontSetting,
    'remember-card': fontData,
  };
  const kinlinecheckout = window.KInlineCheckout;

  for (const [key, value] of Object.entries(fontAttributes)) {
    kinlinecheckout.setFontStyle(key, value);
  }
  kinlinecheckout.onInputValidation(function (elementName, result) {
    let ele = document.getElementById(elementName);
    if (result === VALID.toLowerCase()) {
      ele.classList.remove('is-invalid');
    } else {
      ele.classList.add('is-invalid');
    }
  });
  kinlinecheckout.onGetTokenFailed(function (text) {
    document.getElementById('error-summary').classList.add('show');
  });
};

export const dispatchLoadScriptForKbankPayment = tokenApiKey => {
  removeKBANKCCFromScript();
  loadScript(
    config(KBANK_PAYMENTS_CONFIG_SCRIPT_URL),
    'kbankCC',
    () => {
      window.KInlineCheckout.init();
      KbankLoadFunction(KbankStyleFunction);
    },
    (script, publicTokenApiKey) => {
      const scriptAttributes = {
        'data-apikey': publicTokenApiKey,
        'data-lang': getLanguage()?.includes('TH') ? LANGUAGE_KEYS?.TH : LANGUAGE_KEYS?.EN,
        'data-write-log': 'true',
        'data-currency': 'THB',
      };
      for (const [key, value] of Object.entries(scriptAttributes)) {
        script.setAttribute(key, value);
      }
    },
    tokenApiKey,
  );
};

export const dispatchPaymentChargeApiRequest = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch, tenantId } = checkoutDetails;
  let data = {};
  if (checkoutDetails?.isClickPlaceOrder && checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD) {
    if (checkoutDetails?.defaultCardId) {
      data.orderId = checkoutDetails?.orderId;
      data.cardId = checkoutDetails?.defaultCardId;
    } else {
      data.orderId = checkoutDetails?.orderId;
      data.token = checkoutDetails?.KbankToken;
      data.saveCard = checkoutDetails?.billingAddressAndSaveCard?.enabledSaveCard;
    }
    dispatch(
      kbankChargePayment(
        {
          data,
          tenantId,
        },
        response => {
          if (response?.status === PAYMENT_STATUS?.SUCCESS.toLowerCase()) {
            if (response?.transactionState === PAYMENT_STATUS?.AUTHORIZED) {
              updateCheckoutDetails({ isPaymentTransationCompleted: true });
            } else if (
              response?.transactionState === PAYMENT_STATUS?.PRE_AUTHORIZED &&
              response?.redirectUrl !== null
            ) {
              window.open(response?.redirectUrl, '_self');
            }
          } else if (response?.status === PAYMENT_STATUS?.FAIL) {
            if (response?.transactionState === PAYMENT_STATUS?.DECLINED) {
              updateCheckoutDetails({ isPaymentTransationDeclined: true });
            }
          }
        },
        () => {
          redirectToPaymentFailurePage({ ...props });
        },
      ),
    );
  }
};

export const isLastUsedPaymentIsFromSavedCard = response => {
  const { cards, defaultCardId } = response;
  return cards.some(card => card.id === defaultCardId);
};

//function to get Kbank saved cards
export const dispatchGetKbankSavedCardsApiRequest = (checkoutDetails, updateCheckoutDetails) => {
  const { dispatch } = checkoutDetails;
  dispatch(
    kbankGetSavedCards(
      {
        tenantId: checkoutDetails?.tenantId,
        customerId: checkoutDetails?.customerId,
      },
      response => {
        response?.cards?.length > 0
          ? updateCheckoutDetails({
              savedCards: savedCardResponseFormatted(response),
              defaultCardId: response?.defaultCardId,
              isPaymentTypeSelected: true,
              tender: {
                name: checkoutDetails?.lastUsedPaymentMethod,
                payload: {
                  tender: checkoutDetails?.lastUsedPaymentMethod,
                },
              },
              isLastUsedPaymentIsFromSavedCard: isLastUsedPaymentIsFromSavedCard(response),
              // cardid: response?.defaultCardId,
            })
          : updateCheckoutDetails({
              savedCards: response,
              tender: {
                name: checkoutDetails?.lastUsedPaymentMethod ? checkoutDetails?.lastUsedPaymentMethod : '',
                payload: {
                  tender: checkoutDetails?.lastUsedPaymentMethod ? checkoutDetails?.lastUsedPaymentMethod : '',
                },
              },
            });
      },
      error => {
        console.log('response in kbankGetSavedCards error', error);
      },
    ),
  );
};

export const formatedMasknumber = cardNumber => {
  const firstFour = cardNumber?.slice(0, 4);
  const LastTwo = cardNumber?.slice(-2);
  return `${firstFour} •••• •••• ••${LastTwo}`;
};

export const savedCardResponseFormatted = response => {
  let newResponse = [];

  response?.cards?.forEach((item, index) => {
    newResponse.push({
      expiryDate: item?.expiryDate,
      id: item?.id,
      maskedCardNumber: formatedMasknumber(item?.maskedCardNumber),
      type: item?.type,
      isDefaultCard: item?.id === response?.defaultCardId,
      nameOnCard: item?.nameOnCard,
      noFormattedMaskedCardNumber: item?.maskedCardNumber,
    });
  });
  return newResponse;
};

//function to Delete Kbank saved cards
export const dispatchDeleteKbankSavedCardsApiRequest = (checkoutDetails, updateCheckoutDetails) => {
  const { dispatch } = checkoutDetails;
  dispatch(
    deleteKbankSavedCards(
      {
        tenantId: checkoutDetails?.tenantId,
        customerId: checkoutDetails?.customerId,
        cardId: checkoutDetails?.deleteCardId,
      },
      response => {
        updateCheckoutDetails({
          isPaymentTypeSelected: false,
        });
        dispatchGetKbankSavedCardsApiRequest(checkoutDetails, updateCheckoutDetails);
        toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED), '', 'card-deleted-toast');
      },
      error => {
        toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CARD_DELETED_FAILED), '', 'card-deleted-toast');
      },
    ),
  );
  updateCheckoutDetails({
    deleteCardModal: false,
  });
};

export const dispatchGenerateOtpApiRequest = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  updateCheckoutDetails({
    trueMoneyWalletGeneratetOTPErrorResponse: '',
  });
  checkoutDetails?.dispatch(
    trueMoneyWalletGenetateOtp(
      {
        tenantId: checkoutDetails?.tenantId,
        data: {
          orderId: checkoutDetails?.orderId,
          MobileNumber: checkoutDetails?.validPhoneNumber?.mobileNumber?.split(' ')?.join(''),
        },
      },
      response => {
        updateCheckoutDetails({ trueMoneyWalletGeneratetOTPResponse: response });
      },
      error => {
        updateCheckoutDetails({
          trueMoneyWalletGeneratetOTPErrorResponse: error,
          showPlaceOrderLoading: false,
          isClickPlaceOrder: false,
          isUpdatedOrderActionRequest: false,
          dispatchOrderAction: false,
          isCreateOrderValidateCompleted: false,
          updatedTMW: false,
          paymentEnabled: true,
        });
      },
    ),
  );
};

export const dispatchOrderDynamicApiRequest = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const { dispatch, tenantId } = checkoutDetails;
  const data = {
    orderId: checkoutDetails?.orderId,
  };
  dispatch(
    fetchKbankQROrderID(
      {
        data,
        tenantId,
      },
      response => {
        updateCheckoutDetails({ qrOrderId: response?.qrOrderId });
      },
      error => {
        updateCheckoutDetails({
          isClickPlaceOrder: false,
          isCreateOrderValidateCompleted: false,
        });
        console.log('response in error', error);
      },
    ),
  );
};

export const showToastMessage = error => {
  const imageTemplate = <ImageComponent srcFile={IMAGE_PATH.Error} alt='error image' />;
  return (
    error === OTP_VALIDATION_ACCOUNT_ERROR &&
    toastifyFnWithImage(
      translateWithI18Next(TRANSLATE_MAPPING_KEY.TMW_PHONE_NUMBER_NOT_LINKED),
      imageTemplate,
      'err-toast otp-err-toast',
      true,
    )
  );
};

export const removeKBANKCCFromScript = () => {
  document.getElementById('kbankCC') &&
    document.getElementsByTagName('head')[0].removeChild(document.getElementById('kbankCC'));
};

export const removeQRcodeFormScript = () => {
  let kbankQRForm = document.getElementById('kbankTHQR');
  while (kbankQRForm?.firstChild) {
    kbankQRForm?.removeChild(kbankQRForm?.firstChild);
  }
};

export const addQRcodeFormClass = () => {
  let kbankQRFormContainer = document.querySelector('.payment-container[_kpayment]');
  if (kbankQRFormContainer?.firstChild) {
    !kbankQRFormContainer?.classList?.contains(KBANK_QR_CODE_FORM?.SHOW) &&
      kbankQRFormContainer?.classList?.add(KBANK_QR_CODE_FORM?.SHOW_CLASS);
  }
};

export const removeQRcodeFormClass = () => {
  let kbankQRFormContainer = document.getElementsByClassName(KBANK_QR_CODE_FORM?.SHOW_CLASS);
  while (kbankQRFormContainer.length > 0) {
    kbankQRFormContainer[0].remove();
  }
};

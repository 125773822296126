import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib/i18N';
import { ButtonComp, ButtonType, ContainedButton } from 'atoms/Buttons';
import ImageComponent from 'atoms/Image';
import CONTEXT_REDUCER_MAPPING_KEY from 'common/constants/ContextReducerKeys';
import { ReactSVG } from 'react-svg';
import { TooltipView } from 'atoms/Tooltip/index';
import GoIcon from 'styles/staticAssets/images/GoIcon.png';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { checkForScheduledTime } from 'organisms/ScheduleOrder/ScheduleOrderUtils/ScheduleOrderUtils';
import { Element } from 'react-scroll';

import {
  PAYMENT_GATEWAYS,
  PAYMENT_TENDER_TYPES,
  CART_SELECT_STORE_MSG,
  COUNTRY_CODE,
  OPEN,
  PICKUP_COLLECTION_METHOD,
  AMEX,
  ACTIVE,
  TYPE_EVENT,
  ROUTE_URL,
} from 'common/constants/SharedConstants';
import { displayNotifyMessage } from 'common/sharedTemplates/sharedTemplates';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { kbankToken } from 'organisms/Payment/utils/ThailandPaymentUtils';

import {
  isValidCustomerDetails,
  getTenderIcon,
  getTenderName,
  addToPaymentClickHandler,
  validatePlaceOrderClick,
  changePaymentOptionsHandler,
  resetPaymentChange,
  updateClickPlaceOrderState,
  onChangeCVVForm,
  validateCvv,
  handleDeleteCardModal,
  checkOrderCapacityRecheck,
  checkBalance,
  getErrorMsg,
  deleteCardModalHandler,
} from 'common/utilities/PaymentUtils';
import {
  isPickupDisposition,
  redirectToPrivacyPolicyPage,
  redirectToTermsAndConditionsPage,
  isAsapOrder,
  isSafari,
  isIos,
  getPrivacyPolicyExternalUrl,
  safeUpperCase,
  getDispositionTypeOnAPIFormat,
} from 'common/utilities/utils';
import { ScheduleAlert } from 'molecules/ScheduleAlert/AU/Container/ScheduleAlert';

import CartAlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import CartAlertFormInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import BraintreePaymentView from '../BraintreePaymentView';
import GlobalPaymentView from '../GlobalPaymentView';
import PayUPaymentView from '../PayUPaymentView';
import PayGatePaymentView from '../PayGatePaymentView';
import ThailandPaymentView from '../ThailandPaymentView';
import BillDeskPaymentView from '../BillDeskPaymentView';
import JapanPaymentView from '../JapanPaymentView';
import AlertContainer from 'organisms/AlertComponent/Container/AU/AlertContainer';
import { closeModalHandler, storeClosedOnClickBtnHandler } from 'molecules/ScheduleAlert/AU/utils/ScheduleAlertUtils';
import { scheduleOrderHandler } from '../../../../molecules/ScheduleAlert/AU/utils/ScheduleAlertUtils';
import DgftCreditCardTemplate from '../templates/DgftCreditCardTemplate';
import GlobalPaymentBillingandSaveTemplate from './GlobalPaymentBillingandSaveTemplate';
import AdyenPaymentView from 'organisms/Payment/presentation/AdyenPaymentView';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import IndiaPaymentView from '../IndiaPaymentView';
import ProceedToPayment from 'components/checkout/proceedToPayment';
import { isDeliveryPartner } from 'organisms/DeliveryAddressSearchForm/DeliveryAddressSearchFormUtilities/DeliveryAddressSearchFormUtils';

const getClassForPaymentInfo = checkoutDetails => {
  return checkoutDetails?.isClickAddPayment &&
    (checkoutDetails?.paymentProvider === PAYMENT_CONSTANTS.ADYEN_PAYMENT_TYPE.ADYEN ||
      checkoutDetails?.cartData?.paymentGateway === PAYMENT_TENDER_TYPES?.PHONEPE)
    ? 'col-sm-12'
    : 'col-sm-8';
};

const { SCHEDULE_TIME_NOT_AVAILABLE, SCHEDULE_TIME_NOT_AVAILABLE_BUTTON, SCHEDULE_TIME_NOT_AVAILABLE_MSG } =
  TRANSLATE_MAPPING_KEY;

/*********************************************************************
 * getTitlePanel
 *
 *********************************************************************/
export const getTitlePanel = checkoutDetails => {
  return (
    <div
      className={`labelContent contact-label pl-25px col-12 ${
        checkoutDetails?.isClickAddPayment &&
        (checkoutDetails?.paymentProvider === PAYMENT_CONSTANTS.ADYEN_PAYMENT_TYPE.ADYEN ||
          checkoutDetails?.cartData?.paymentGateway === PAYMENT_TENDER_TYPES?.PHONEPE)
          ? 'col-sm-8'
          : 'col-sm-4'
      }`}
    >
      <span>{translateWithI18Next(checkoutDetails?.paymentPanelTitle)}</span>
    </div>
  );
};

/*********************************************************************
 * getPaymentTagline
 *
 *********************************************************************/
export const getPaymentTagline = title => {
  return <div className='payment-tagline-text'>{title}</div>;
};

/*********************************************************************
 * getEnablePaymentCtaForPickupMethod
 * if enablePickupMethodDuringCheckout is false or disposition is not pickup, return true
 * Otherwise check if the pickup method radio cta was selected & carNumber entered for curbside before returning true
 *********************************************************************/
export const getEnablePaymentCtaForPickupMethod = ({ collectionPoint, enablePickupMethodDuringCheckout, service }) => {
  const { carNumber, name } = collectionPoint || {};
  const { IN_STORE, DRIVE_THRU, CURB_SIDE, DINE_IN } = PICKUP_COLLECTION_METHOD;

  return (
    !isPickupDisposition(service) ||
    !enablePickupMethodDuringCheckout ||
    name === IN_STORE ||
    name === DRIVE_THRU ||
    (name === CURB_SIDE && !!carNumber) ||
    name === DINE_IN
  );
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const getAddPaymentMethod = (checkoutDetails, updateCheckoutDetails, className = null) => {
  return (
    !checkoutDetails?.isClickAddPayment &&
    checkoutDetails?.tender?.name === '' && (
      <>
        <div className={`addPaymentContent d-flex mt-20px ${className}`}>
          <ContainedButton
            variant={ButtonType.primary}
            inactive={!checkoutDetails?.paymentEnabled}
            fullWidth
            data-testid='pay-button'
            onClick={() => addToPaymentClickHandler(checkoutDetails, updateCheckoutDetails, false)}
          >
            {checkoutDetails?.payNowAllowedTenders?.length && isPickupDisposition(checkoutDetails?.service)
              ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.I_WILL_PAY_NOW)
              : translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADD_PAYMENT_METHOD_HEADER)}
          </ContainedButton>
        </div>
        {checkoutDetails?.enablePayOnArrival &&
          checkoutDetails?.enablePayByCashOption &&
          isPickupDisposition(checkoutDetails?.service) && (
            <div className={`d-flex payonarrivalbutton`}>
              <ContainedButton
                variant={ButtonType.tertiary}
                inactive={!checkoutDetails?.paymentEnabled}
                fullWidth
                data-testid='pay-button-pay-pickup'
                onClick={() => addToPaymentClickHandler(checkoutDetails, updateCheckoutDetails, true)}
              >
                {translateWithI18Next(TRANSLATE_MAPPING_KEY?.I_WILL_PAY_WHEN_PICKUP)}
              </ContainedButton>
            </div>
          )}
      </>
    )
  );
};

/*********************************************************************
 * getPaymentErrorTemplate
 *
 *********************************************************************/
export const getPaymentErrorTemplate = checkoutDetails => {
  const getErrorMessage = () => {
    if (checkoutDetails?.allowedTenders?.length === 0) {
      /* Display error message if allowedTenders length is 0 */
      return TRANSLATE_MAPPING_KEY?.NO_PAYMENT_OPTION_AVAILABLE;
    } else if (checkoutDetails?.userParams?.loggedIn && !isValidCustomerDetails(checkoutDetails)) {
      /* Display error message if Sing-in User without valid customer details*/
      return TRANSLATE_MAPPING_KEY?.INVALID_CUSTOMER_INFO_ERROR;
    }
    return null;
  };

  return (
    getErrorMessage() && (
      <div className='row row-cols-12 mt-10px payment-failed-error-box'>
        <ImageComponent srcFile={IMAGE_PATH.Error} className='mt-3px mr-5px' />
        <div className='ml-10px'>{translateWithI18Next(getErrorMessage())}</div>
      </div>
    )
  );
};

export const getPaymentMaskClassName = checkoutDetails =>
  checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS?.JAPAN_PAYMENTS &&
  checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS?.THAILAND_PAYMENTS
    ? 'payment-card-mask'
    : '';

/*********************************************************************
 * getContinuePlaceOrderPaymentButton
 *
 *********************************************************************/
export const getContinuePlaceOrderPaymentButton = params => {
  const { checkoutDetails, onClickCallBackHandler = null, label = null, disabled = null, loading = false } = params;
  return (
    <div className='checkoutAmountDetails'>
      {checkoutDetails?.enablePaymentProviderPlaceOrderBtn &&
      checkoutDetails?.tender?.name !== PAYMENT_TENDER_TYPES.PAY_BY_CASH &&
      !checkoutDetails?.cardid ? (
        <div id={`payment-place-order-cont`}>
          {checkoutDetails?.paymentProviderPlaceOrderBtn && checkoutDetails?.paymentProviderPlaceOrderBtn(params)}
        </div>
      ) : (
        <ContainedButton
          id='payment-place-order'
          variant={ButtonType.primary}
          inactive={
            (disabled && !checkoutDetails?.isAdyenCardValid) ||
            (checkoutDetails?.showSavedCardCvv &&
              checkoutDetails?.cardid &&
              (checkoutDetails?.savedCardCvv?.length === 0 || checkoutDetails?.cvvError !== ''))
          }
          loading={loading}
          fullWidth
          onClick={e => onClickCallBackHandler?.(e, params)}
          data-testid='continue-to-payment-btn'
        >
          {label || getContinuePlaceOrderPaymentButtonLabel(checkoutDetails)}
        </ContainedButton>
      )}
    </div>
  );
};

/*********************************************************************
 *getContinuePlaceOrderPaymentButtonLabel
 *
 *********************************************************************/
export const getContinuePlaceOrderPaymentButtonLabel = checkoutDetails => {
  return (
    <span>
      {translateWithI18Next(TRANSLATE_MAPPING_KEY.CONTINUE_PAYMENT)}
      <span className='rupee-text'>
        {' '}
        {checkoutDetails?.currencySymbol}
        {checkoutDetails?.finalAmount}{' '}
      </span>
    </span>
  );
};

/*********************************************************************
 * getGenerateClientTokenLoadingTemplate
 *
 *********************************************************************/
export const getGenerateClientTokenLoadingTemplate = isGenerateClientTokenLoading => {
  return (
    isGenerateClientTokenLoading && (
      <div className='placeholder'>
        <div className='table'>
          <div className='loading table-content' />
          <div className='loading table-content' />
          <div className='loading table-content' />
        </div>
      </div>
    )
  );
};

/*********************************************************************
 * getTermAndPolicyTemplate
 *
 *********************************************************************/
export const getTermAndPolicyTemplate = props => {
  const { paymentAgreementAndOrderFlag, marketConfigReducer } = props.checkoutDetails;
  const copyrightContentData = marketConfigReducer?.headerData?.footer?.fields?.footerContent;
  const privacyPolicyExternalUrl = getPrivacyPolicyExternalUrl(copyrightContentData);
  return (
    <div className=' info-note txt-align-center termsAndCond'>
      <span className='link-span-box'>
        {!paymentAgreementAndOrderFlag &&
          (props.checkoutDetails.paymentProvider === PAYMENT_GATEWAYS.BRAIN_TREE
            ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLICK_PLACE_ORDER_CONFIRM_AGREE)
            : translateWithI18Next(TRANSLATE_MAPPING_KEY?.VIEW_OUR))}{' '}
        <span className='link' data-testid='terms-condition' onClick={() => redirectToTermsAndConditionsPage(props)}>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.TERMS_OF_USE)}
        </span>{' '}
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CHECKOUT_TC_AND)}{' '}
        <span
          className='link'
          data-testid='privacy-policy'
          onClick={() => redirectToPrivacyPolicyPage(props, privacyPolicyExternalUrl)}
        >
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.PRIVACY)}
        </span>
        {paymentAgreementAndOrderFlag && translateWithI18Next(TRANSLATE_MAPPING_KEY?.AGREE_AND_ORDER)}
      </span>
    </div>
  );
};

/*********************************************************************
 * getPaymentAdditionalOptionPanel
 *
 *********************************************************************/
export const getPaymentAdditionalOptionPanel = (params, childeren) => {
  const { checkoutDetails } = params;
  if (checkoutDetails?.isPaymentTypeSelected) {
    switch (checkoutDetails?.tender?.name) {
      case PAYMENT_TENDER_TYPES?.CREDIT_CARD:
        return checkoutDetails?.enablePaymentCardInputPanel && !checkoutDetails?.cardid ? childeren(params) : null;
      case PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD:
        return checkoutDetails?.enableDgftCardPanel && !checkoutDetails?.cardid ? (
          <DgftCreditCardTemplate {...params} />
        ) : null;
      case PAYMENT_TENDER_TYPES?.TRUE_MONEY_WALLET:
        return checkoutDetails?.enableTrueMoneyWallet && !checkoutDetails?.cardid ? childeren(params) : null;
      case PAYMENT_TENDER_TYPES?.NET_BANKING:
      case PAYMENT_TENDER_TYPES?.OTHER_WALLET:
        return null;
      //no default
    }
  }
  return null;
};

// default value function
export const getSelectDefaultPaymentType = (isSavedCard, checkoutDetails, selectedPaymentDetails, index) => {
  const lastUsedPayment = checkoutDetails?.lastUsedPaymentMethod;
  if (
    (checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS?.JAPAN_PAYMENTS ||
      checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS?.THAILAND_PAYMENTS) &&
    checkoutDetails?.savedCards?.length &&
    isSavedCard &&
    lastUsedPayment === PAYMENT_TENDER_TYPES.CREDIT_CARD
  ) {
    return selectedPaymentDetails?.isDefaultCard ? true : false;
  } else if (
    checkoutDetails?.savedCards?.length &&
    checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS?.THAILAND_PAYMENTS &&
    (lastUsedPayment === PAYMENT_TENDER_TYPES.CREDIT_CARD ||
      lastUsedPayment === PAYMENT_TENDER_TYPES.PAY_BY_CASH ||
      lastUsedPayment === PAYMENT_TENDER_TYPES.DGFT_CREDIT_CARD)
  ) {
    if (isSavedCard) {
      return index === 0;
    } else {
      return false;
    }
  } else {
    if (
      lastUsedPayment === PAYMENT_TENDER_TYPES.PAY_BY_CASH &&
      checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS?.THAILAND_PAYMENTS &&
      (selectedPaymentDetails === PAYMENT_TENDER_TYPES.CREDIT_CARD ||
        selectedPaymentDetails === PAYMENT_TENDER_TYPES.DGFT_CREDIT_CARD)
    ) {
      return true;
    }
    return selectedPaymentDetails === lastUsedPayment;
  }
};

const isSavedCardDetails = (isSavedCard, selectedPaymentDetails) => {
  if (isSavedCard) {
    return selectedPaymentDetails?.id;
  } else {
    return selectedPaymentDetails;
  }
};
/*********************************************************************
 * getPaymentOptionPanel
 *
 *********************************************************************/
export const getPaymentOptionPanel = (params, childeren) => {
  const {
    checkoutDetails,
    updateCheckoutDetails,
    selectedPaymentDetails,
    index,
    isSavedCard,
    isCashOnArrival,
    noborder,
  } = params;
  return (
    <>
      {/* Saved Card and other Payment option display */}
      <div
        id={`${isSavedCard ? 'savedCard-' + selectedPaymentDetails?.id : 'payment-' + selectedPaymentDetails}`}
        className={`d-flex flex-column align-content-stretch ${
          isSavedCard ? 'savedcard-option' : (isCashOnArrival && 'cash-on-delivery-checkox') || 'payoption-button'
        } ${noborder ? 'no-border' : ''} ${
          checkoutDetails?.tender?.name === selectedPaymentDetails &&
          checkoutDetails?.isPaymentTypeSelected &&
          !isSavedCard
            ? 'cc-card-cl'
            : ''
        }`}
        data-testid={isSavedCard ? 'pay-options' : 'tender-options'}
      >
        <div className={`radioBtns`}>
          <input
            type='radio'
            data-testid={`radio-handler-${isSavedCardDetails(isSavedCard, selectedPaymentDetails)}`}
            id={isSavedCardDetails(isSavedCard, selectedPaymentDetails)}
            name='tenderOptions'
            value={isSavedCard ? selectedPaymentDetails?.id : selectedPaymentDetails}
            defaultChecked={getSelectDefaultPaymentType(isSavedCard, checkoutDetails, selectedPaymentDetails, index)}
            onClick={() =>
              changePaymentOptionsHandler(updateCheckoutDetails, selectedPaymentDetails, isSavedCard, checkoutDetails)
            }
          />
          <label htmlFor={isSavedCardDetails(isSavedCard, selectedPaymentDetails)}>
            {
              <>
                <span className='tender-label' data-testid='qr-code-text' tabIndex='0'>
                  {isSavedCard ? (
                    <span className={`${getPaymentMaskClassName(checkoutDetails)}`}>
                      {selectedPaymentDetails?.maskedCardNumber?.replace?.(/x/gi, '')}
                    </span>
                  ) : (
                    getTenderName(checkoutDetails?.tendersContent, selectedPaymentDetails)
                  )}
                </span>
                <span
                  className={`${
                    isSavedCard && checkoutDetails?.showDeleteSavedCard ? 'delete-icon-tender-image' : 'tender-image'
                  }`}
                >
                  <img
                    src={getTenderIcon(
                      checkoutDetails?.tendersContent,
                      isSavedCard ? selectedPaymentDetails?.type : selectedPaymentDetails,
                    )}
                    className={`${
                      isSavedCard && checkoutDetails?.showDeleteSavedCard ? 'delete-icon-tender-image' : 'tender-image'
                    }`}
                    alt=''
                  />
                </span>
                {checkoutDetails?.showDeleteSavedCard && isSavedCard && (
                  <span
                    className='delete-card'
                    onClick={event => handleDeleteCardModal(event, updateCheckoutDetails, selectedPaymentDetails)}
                  >
                    <ImageComponent srcFile={IMAGE_PATH?.PopupCloseBtn} className='delete-card-icon' />
                  </span>
                )}
              </>
            }
          </label>
        </div>
        {/* Children append at runtime based on Payament selection */}
        {!isSavedCard &&
          checkoutDetails?.isPaymentTypeSelected &&
          checkoutDetails?.tender?.name === selectedPaymentDetails &&
          (checkoutDetails?.cardid === null || checkoutDetails?.cardid === undefined) && (
            <>{getPaymentAdditionalOptionPanel(params, childeren)}</>
          )}
        {checkoutDetails?.showSavedCardCvv && isSavedCard && selectedPaymentDetails?.id === checkoutDetails?.cardid && (
          <div className='cvv-conatiner'>
            <div className='input-container'>
              <input
                data-testid='search-input-address'
                value={checkoutDetails?.savedCardCvv}
                onChange={event => onChangeCVVForm(event, updateCheckoutDetails, selectedPaymentDetails?.type)}
                onBlur={() =>
                  validateCvv(checkoutDetails?.savedCardCvv, updateCheckoutDetails, selectedPaymentDetails?.type)
                }
                name='savedCardCvv'
                required
                type='text'
                maxLength={selectedPaymentDetails?.type === AMEX ? 4 : 3}
              />
              <label className='address-label address-label-color'>
                {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV_CREDITCARD_LABEL)}
              </label>
              <span className='tool-tip-position'>
                <TooltipView placement='left' title={translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV_CREDITCARD)}>
                  <img className='tooltip-view' src={IMAGE_PATH.Tooltip} alt='tooltip' />
                </TooltipView>
              </span>
              {checkoutDetails?.cvvError && (
                <div className='error d-flex cvvErrorColour'>
                  <img className='mr-2' src={IMAGE_PATH?.Ellipse} alt='Error visible' /> {checkoutDetails?.cvvError}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* enableKFCGiftCard for Japan*/}
      {renderGiftCardTemplate({ checkoutDetails, selectedPaymentDetails, checkBalance, getErrorMsg })}
      {}
    </>
  );
};
export const validateToDisplayTender = (tendersList, checkoutDetails) => {
  return tendersList?.filter(item => {
    switch (item) {
      case PAYMENT_TENDER_TYPES.DGFT_APPLEPAY:
      case PAYMENT_TENDER_TYPES.APPLE_PAY:
        return isIos() || isSafari() ? item : null;
      case PAYMENT_TENDER_TYPES.GOOGLE_PAY:
        return !isIos() && !isSafari() ? item : null;
      case PAYMENT_TENDER_TYPES?.GIFT_CARD:
        return checkoutDetails?.userParams?.loggedIn && checkoutDetails?.giftCardStatus === ACTIVE ? item : null;
      case PAYMENT_TENDER_TYPES?.POINT_PONTA:
      case PAYMENT_TENDER_TYPES?.POINT_RAKUTEN:
        return null;
      default:
        return item;
    }
  });
};

/*********************************************************************
 * getOtherPaymentOptionsTemplate
 *
 *********************************************************************/
export const getOtherPaymentOptionsTemplate = (params, childeren) => {
  const { checkoutDetails } = params;
  let allowedTenderList =
    checkoutDetails?.isClickPayOnArrival && checkoutDetails?.payOnArrivalAllowedTenders?.length
      ? checkoutDetails?.payOnArrivalAllowedTenders
      : checkoutDetails?.payNowAllowedTenders;
  allowedTenderList = checkoutDetails?.enablePayOnArrival
    ? validateToDisplayTender(allowedTenderList, checkoutDetails)
    : validateToDisplayTender(checkoutDetails?.allowedTenders, checkoutDetails);
  return allowedTenderList?.map?.((tenders, index) => {
    return getPaymentOptionPanel(
      {
        ...params,
        selectedPaymentDetails: tenders,
        index,
        isSavedCard: false,
        noborder: index + 1 === allowedTenderList?.length,
        isCashOnArrival:
          checkoutDetails?.enablePayOnArrival &&
          checkoutDetails?.isClickPayOnArrival &&
          tenders === PAYMENT_TENDER_TYPES.PAY_BY_CASH,
      },
      childeren,
    );
  });
};

/*********************************************************************
 * getsavedCardOptionTemplate
 *
 *********************************************************************/
export const getsavedCardOptionTemplate = (checkoutDetails, updateCheckoutDetails) => {
  return checkoutDetails?.savedCards?.map?.((card, index) =>
    getPaymentOptionPanel({
      checkoutDetails,
      updateCheckoutDetails,
      selectedPaymentDetails: card,
      index,
      isSavedCard: true,
    }),
  );
};

/*********************************************************************
 * getPaymentOptionsTemplate
 *
 *********************************************************************/
export const getPaymentOptionsTemplate = (params, childeren) => {
  const { checkoutDetails, updateCheckoutDetails } = params;
  const { savedCards, userParams, isOrderWithoutPayment, driverTipDisplayAmount } = checkoutDetails;

  return (
    (!isOrderWithoutPayment || driverTipDisplayAmount !== '') && (
      <>
        {/* If logged-in user, show saved Card title  */}
        {Boolean(userParams?.loggedIn && savedCards?.length) &&
          !checkoutDetails?.isClickPayOnArrival &&
          getPaymentTagline?.(translateWithI18Next?.(TRANSLATE_MAPPING_KEY?.SAVED_CARD_TITLE))}

        {/* If logged-in user, show saved Card details  */}
        {!checkoutDetails?.isAddNewCard &&
          !checkoutDetails?.isClickPayOnArrival &&
          getsavedCardOptionTemplate(checkoutDetails, updateCheckoutDetails)}

        {/* If logged-in user, show other payment title  */}
        {Boolean(userParams?.loggedIn && savedCards?.length) &&
          !checkoutDetails?.isClickPayOnArrival &&
          getPaymentTagline?.(translateWithI18Next?.(TRANSLATE_MAPPING_KEY.OTHER_PAYMENT_TITILE))}

        {/* Display other payment types */}
        {getOtherPaymentOptionsTemplate(params, childeren)}
      </>
    )
  );
};

/*********************************************************************
 * getPayment change link
 *
 *********************************************************************/

export const otherPaymentChangeLink = (checkoutDetails, updateCheckoutDetails) => {
  return (
    checkoutDetails?.enablePayOnArrival &&
    checkoutDetails?.enablePayByCashOption &&
    checkoutDetails?.isClickAddPayment && (
      <div
        className={`change-payment`}
        data-testid='other-payment-change-link'
        onClick={() => resetPaymentChange(updateCheckoutDetails)}
      >
        <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.ORDER_TYPE_CHANGE)}</span>
      </div>
    )
  );
};

export const scrollElement = () => (
  <Element name='checkoutPaymentScrollPosition'>
    {displayNotifyMessage(translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_CARD_DETAILS))}
  </Element>
);

export const closeOrderCapacityErrorModal = (updateCheckoutDetails, userOrderStateDispatch) => {
  updateCheckoutDetails({ shouldDisplayOrderCapacityError: false });
  closeModalHandler(userOrderStateDispatch);
};

export const proceedToPaymentTemplate = (checkoutDetails, updateCheckoutDetails) => {
  const { tender, formPayload } = checkoutDetails || {};
  return (
    <>
      {tender?.name !== '' && (
        <div className='d-flex flex-column mt-20px'>
          <ButtonComp
            className='tenderButton'
            data-testid='tender-btn'
            onClick={() => updateCheckoutDetails({ openPaymentPopup: true })}
          >
            {tender && tender?.name && tender?.payload && tender?.payload?.url && (
              <span className='payment-image'>
                <img src={tender?.payload?.url} alt='TenderImage' />
              </span>
            )}
            {tender?.name === PAYMENT_TENDER_TYPES.CREDIT_CARD && (
              <div className='card-number-image'>
                <svg {...formPayload?.svgSource} />
              </div>
            )}
            <span className='col-12 col-xs-10'>
              {tender?.name === PAYMENT_TENDER_TYPES.CREDIT_CARD &&
                'x - ' + formPayload?.cardNumber?.trim()?.substr(formPayload?.cardNumber.trim()?.length - 4)}

              {(tender?.name === PAYMENT_TENDER_TYPES.NET_BANKING ||
                tender?.name === PAYMENT_TENDER_TYPES.OTHER_WALLET) &&
                tender?.payload?.displayName + ' : ' + formPayload?.bankName}

              {tender?.name === PAYMENT_TENDER_TYPES.GIFT_CARD &&
                tender?.payload?.displayName + ': #' + formPayload?.AdditionalAttributes?.GiftCardNumber}

              {(tender?.name === PAYMENT_TENDER_TYPES.PAYTM ||
                tender?.name === PAYMENT_TENDER_TYPES.PHONEPE ||
                tender?.name === PAYMENT_TENDER_TYPES.PAY_BY_CASH ||
                tender?.name === PAYMENT_TENDER_TYPES.UPI) &&
                tender?.payload?.displayName}
            </span>

            <span className='right-arrow-icon'>
              <img src={GoIcon} alt='' />
            </span>
          </ButtonComp>
          {tender?.name === PAYMENT_TENDER_TYPES.PAYTM && (
            <span className='paytmPaymentProcess mt-10px'> * Payment will process after place your order.</span>
          )}
        </div>
      )}
      <ProceedToPayment updateCheckoutDetails={updateCheckoutDetails} checkoutDetails={checkoutDetails} />
    </>
  );
};

/*********************************************************************
 * getPaymentBaseTemplate
 *
 *********************************************************************/
export const getPaymentBaseTemplate = params => {
  const { checkoutDetails, getCartAvailability, updateCheckoutDetails, dispositionNew, history, deliveryPartner } =
    params;

  const {
    isClickAddPayment,
    showPlaceOrderLoading,
    isPaymentTypeSelected,
    isCateringSomeOneElseContactDetailsAreValid,
    placeOrderButtonEnabled,
    timeBasedCetegory,
    isRetryPaymentErrorEnabled,
    paymentEnabled,
    showGiftCardOrVoucher,
    isOrderWithoutPayment,
    enableOrderCapacityManagement,
    total,
    isClickPlaceOrder,
    paymentProvider,
  } = checkoutDetails || {};
  const paymentGatewayValidation =
    checkoutDetails?.cartData?.paymentGateway === PAYMENT_TENDER_TYPES?.PHONEPE && !checkoutDetails?.isValid;
  return (
    <div className={`row row-cols-12 mt-10px payment-info`}>
      {/* Display Payment title  */}

      {!checkoutDetails?.isRenderFromRNA && getTitlePanel(checkoutDetails)}

      <div className={`payment-au px-25px ${getClassForPaymentInfo(checkoutDetails)} col-12 mb-40px  payment-sa`}>
        {/* payment card validate error message */}
        {isRetryPaymentErrorEnabled && scrollElement()}
        {/* Payment option change option */}
        {otherPaymentChangeLink(checkoutDetails, updateCheckoutDetails)}
        {/* Display error message */}
        {getPaymentErrorTemplate(checkoutDetails)}
        {showGiftCardOrVoucher && total === 0 && getZeroOrderMessage(checkoutDetails)}
        {/* Display Click Add payment */}
        {getAddPaymentMethod(checkoutDetails, updateCheckoutDetails, 'checkoutAmountDetails')}
        {/* After Add Payment view */}
        {(isClickAddPayment || (isOrderWithoutPayment && total === 0 && showGiftCardOrVoucher)) && (
          <>
            {/* Based on tenant UI will update */}
            {getGatewayPaymentView(params)}

            {/* Continue Payment button */}
            {checkoutDetails?.enableContinuePayment &&
              !checkoutDetails?.isClickContinuePayment &&
              getContinuePlaceOrderPaymentButton({
                checkoutDetails,
                updateCheckoutDetails,
                onClickCallBackHandler: () => updateCheckoutDetails({ isClickContinuePayment: true }),
                label: translateWithI18Next(TRANSLATE_MAPPING_KEY?.CONTINUE),
                disabled:
                  !isPaymentTypeSelected ||
                  showPlaceOrderLoading ||
                  !isCateringSomeOneElseContactDetailsAreValid ||
                  !placeOrderButtonEnabled,
                loading: showPlaceOrderLoading,
              })}

            {/* Place order Payment button */}
            {(!checkoutDetails?.enableContinuePayment || checkoutDetails?.isClickContinuePayment) &&
              checkoutDetails?.showPlaceOrderButton &&
              getContinuePlaceOrderPaymentButton({
                checkoutDetails,
                updateCheckoutDetails,
                onClickCallBackHandler: e => {
                  if (paymentProvider === PAYMENT_CONSTANTS?.ADYEN_PAYMENT_TYPE?.ADYEN) {
                    updateCheckoutDetails({ isClickPlaceOrder: true });
                    window?.dropin?.submit();
                    return;
                  }
                  safeUpperCase(checkoutDetails?.countryCode) === COUNTRY_CODE?.JP &&
                    checkoutDetails?.userOrderStateDispatch?.({
                      type: CONTEXT_REDUCER_MAPPING_KEY?.SHOW_BUCKET_LOADER,
                      value: true,
                    });
                  if (enableOrderCapacityManagement && !isDeliveryPartner(deliveryPartner)) {
                    checkOrderCapacityRecheck(
                      checkoutDetails,
                      updateCheckoutDetails,
                      dispositionNew,
                      getCartAvailability,
                    );
                  } else if (
                    checkoutDetails?.enablePaymentProviderTHPlaceOrderBtn &&
                    !checkoutDetails?.cardid &&
                    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CREDIT_CARD &&
                    !checkoutDetails?.isLastUsedPaymentIsFromSavedCard
                  ) {
                    kbankToken(e, params);
                  } else if (
                    getDispositionTypeOnAPIFormat(checkoutDetails?.service)?.toUpperCase() === TYPE_EVENT &&
                    !enableOrderCapacityManagement
                  ) {
                    updateCheckoutDetails({ isClickPlaceOrder: false });
                    history.push(ROUTE_URL.CART);
                  } else {
                    updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
                  }
                },
                label: translateWithI18Next(TRANSLATE_MAPPING_KEY?.PLACE_ORDER),
                disabled:
                  isClickPlaceOrder ||
                  !isPaymentTypeSelected ||
                  showPlaceOrderLoading ||
                  !isCateringSomeOneElseContactDetailsAreValid ||
                  !placeOrderButtonEnabled ||
                  !paymentEnabled ||
                  paymentGatewayValidation,
                loading: showPlaceOrderLoading,
                timeBasedCetegory: timeBasedCetegory,
              })}
            {/* View Term and policy */}
            {validatePlaceOrderClick(checkoutDetails) &&
              checkoutDetails?.termsAndConditionsShow &&
              getTermAndPolicyTemplate(params)}
            {/* Schedule Alert Modal */}
            {params?.checkoutDetails?.scheduleModalOpen && getScheduleAlertModal(params)}
            {/* Delete Card Modal */}
            {params?.checkoutDetails?.deleteCardModal &&
              deleteCardModalHandler(params?.checkoutDetails, params?.updateCheckoutDetails)}
          </>
        )}
      </div>
    </div>
  );
};

/*********************************************************************
 * getScheduleAlertModal
 *
 *********************************************************************/
export const getScheduleAlertModal = params => {
  const {
    scheduleModalOpen,
    store,
    dispatch,
    cartData,
    userOrderStateDispatch,
    tenantId,
    currentStoreInfo,
    enableClickCollectScheduleOrder,
    enableOrderCapacityManagement,
    enableScheduleOrder,
    storeClosesSoon,
    isFutureDateTimeOrder,
    isTemproryClosed,
    allowScheduleOrderForDispositions,
    date,
    epochTime,
  } = params?.checkoutDetails;

  const enableClickCollectScheduleOrderFlag =
    enableClickCollectScheduleOrder && allowScheduleOrderForDispositions.includes(currentStoreInfo?.service);
  const storeStatus = currentStoreInfo?.summary?.status?.currentStatus;
  const isStoreOpen = Boolean(storeStatus === OPEN);
  const storeTimeZone = currentStoreInfo?.summary?.timeZoneIANA;
  const promiseTime = currentStoreInfo?.summary?.timings?.asap?.promiseTime;
  const scheduleTimeCheck = checkForScheduledTime({
    enableScheduleOrder,
    enableClickCollectScheduleOrder: enableClickCollectScheduleOrderFlag,
    timeZone: storeTimeZone,
    storeSelectedDate: date,
    storeSelectedTime: epochTime,
    promiseTime,
  });
  let modalType = '';
  if (
    (enableClickCollectScheduleOrderFlag &&
      ((!isStoreOpen && isTemproryClosed) || scheduleTimeCheck) &&
      isFutureDateTimeOrder) ||
    (enableScheduleOrder && scheduleTimeCheck && !storeClosesSoon && isFutureDateTimeOrder)
  ) {
    modalType = scheduleModalOpen && (
      <ScheduleAlert
        storeInfo={currentStoreInfo}
        storeStatus={storeStatus}
        history={{ ...params }}
        userOrderStateDispatch={userOrderStateDispatch}
        dispatch={dispatch}
        tenantId={tenantId}
        cartDataId={cartData?.id}
        isAsapFlagAvailable={isAsapOrder(currentStoreInfo)}
        isTemproryClosed={isTemproryClosed}
        enableClickCollectScheduleOrderFlag={enableClickCollectScheduleOrderFlag}
        scheduleTimeCheck={scheduleTimeCheck}
      />
    );
  } else if (enableOrderCapacityManagement) {
    modalType = scheduleModalOpen && (
      <CartAlertFormInsideModel
        handleClose={() => closeOrderCapacityErrorModal(params?.updateCheckoutDetails, userOrderStateDispatch)}
        classNames='clear-cart-modal item-unavailable cart-checkout-alert schedule-time-lapsed order-capacity-error'
        CartAlertForm={
          <CartAlertForm
            title={translateWithI18Next(SCHEDULE_TIME_NOT_AVAILABLE)}
            contentMsg={translateWithI18Next(SCHEDULE_TIME_NOT_AVAILABLE_MSG)}
            imageSrc={IMAGE_PATH.Timeout_Red}
            altText={''}
            confirmButtonText={translateWithI18Next(SCHEDULE_TIME_NOT_AVAILABLE_BUTTON)}
            confirmHandler={event => scheduleOrderHandler(event, userOrderStateDispatch, currentStoreInfo, true)}
          />
        }
      />
    );
  } else {
    modalType = scheduleModalOpen && (
      <AlertContainer
        handleClose={() => closeModalHandler(userOrderStateDispatch)}
        showAlert={scheduleModalOpen}
        onClickBtnHandler={event => storeClosedOnClickBtnHandler(event, userOrderStateDispatch)}
        stoerName={store?.summary?.name}
        contentMsg={CART_SELECT_STORE_MSG}
      />
    );
  }
  return modalType;
};

/*********************************************************************
 * getGatewayPaymentView
 *
 *********************************************************************/
export const getGatewayPaymentView = props => {
  switch (props?.checkoutDetails?.paymentProvider) {
    case PAYMENT_GATEWAYS.BILLDESK:
      return <IndiaPaymentView {...props} />;
    case PAYMENT_GATEWAYS.BRAIN_TREE:
      return <BraintreePaymentView {...props} />;
    case PAYMENT_GATEWAYS.GLOBAL_PAYMENTS:
      return <GlobalPaymentView {...props} />;
    case PAYMENT_GATEWAYS.PAYU:
      return <PayUPaymentView {...props} />;
    case PAYMENT_GATEWAYS.PAY_GATE:
      return <PayGatePaymentView {...props} />;
    case PAYMENT_GATEWAYS.JAPAN_PAYMENTS:
      return <JapanPaymentView {...props} />;
    case PAYMENT_GATEWAYS.THAILAND_PAYMENTS:
      return <ThailandPaymentView {...props} />;
    case PAYMENT_GATEWAYS.ADYEN_PAYMENTS:
      return <AdyenPaymentView {...props} />;

    default:
      return <></>;
  }
};

export const getZeroOrderMessage = checkoutDetails => {
  const { subTotal, paymentDiscountTotal, isClickAddPayment, isOrderWithoutPayment } = checkoutDetails;
  const isMealFullyCoveredFlag = subTotal - paymentDiscountTotal <= 0;
  return (
    isMealFullyCoveredFlag &&
    (isOrderWithoutPayment || !isClickAddPayment) && (
      <div className='wicode-summary-checkout-notification-meal-fully-covered'>
        <div className='notify-alert-wicode'>
          <ReactSVG
            src={checkoutDetails?.isOrderWithoutPayment ? IMAGE_PATH?.TickWithCircle : IMAGE_PATH?.GreenCheck}
            alt='Warning_Green'
            className='location-alert-icon'
          />
          <span>
            {checkoutDetails?.isOrderWithoutPayment
              ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.ORDER_WITHOUT_PAYMENT)
              : translateWithI18Next(TRANSLATE_MAPPING_KEY?.MEAL_FULLY_COVERED_DELIVERY_FEE_REMAINING)}
          </span>
        </div>
      </div>
    )
  );
};

export const tamplateWithError = params => {
  const {
    checkoutDetails,
    selectedPaymentDetails,
    checkBalance: checkBalanceVal,
    getErrorMsg: getErrorMessage,
  } = params;
  return (
    <>
      <div className='balance-amount' data-testid='kfc-balance'>
        {checkBalanceVal(checkoutDetails, selectedPaymentDetails)}
      </div>
      <div className='row row-cols-12 mt-10px payment-failed-error-box'>
        <ImageComponent srcFile={IMAGE_PATH.Error} className='mt-3px mr-5px' />
        <div className='ml-10px'>{translateWithI18Next(getErrorMessage(checkoutDetails))}</div>
      </div>
    </>
  );
};

export const getGiftCardTemplate = params => {
  const {
    checkoutDetails,
    selectedPaymentDetails,
    checkBalance: checkBalanceVal,
    getErrorMsg: getErrorMessage,
  } = params;
  return checkBalanceVal(checkoutDetails, selectedPaymentDetails) && getErrorMessage(checkoutDetails) ? (
    tamplateWithError(params)
  ) : (
    <div className='balance-amount'>{checkBalanceVal(checkoutDetails, selectedPaymentDetails)}</div>
  );
};

export const renderGiftCardTemplate = params =>
  params?.checkoutDetails?.enableKFCGiftCard && getGiftCardTemplate(params);
export const getBillingOrShippingAddressJSX = ({ checkoutDetails, updateCheckoutDetails }) => (
  <div className={`row row-cols-12 billingaddress-container }`}>
    <div className={`${checkoutDetails?.isAddNewCard ? 'col-sm-4' : 'col-sm-12'} col-12`}>
      <div className={`labelContent ${checkoutDetails?.isAddNewCard ? 'labelContentBillingAddress' : ''} `}>
        <span>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.BILLING_ADDRESS)}</span>
      </div>
    </div>
    <div className={`${checkoutDetails?.isAddNewCard ? 'col-sm-8' : 'col-sm-12'} col-12`}>
      <>
        <GlobalPaymentBillingandSaveTemplate
          checkoutDetails={checkoutDetails}
          updateCheckoutDetails={updateCheckoutDetails}
        />
      </>
    </div>
  </div>
);

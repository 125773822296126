import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import PaymentOptions from './PaymentOptions';
import PaymentForm from './PaymentForm';
import { resetValidateBasketAction, validateBasketAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import {
  getNetbankingConfigurations,
  getOtherWalletsList,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { PAYMENT_TENDER_TYPES } from '../../common/constants/SharedConstants';
import { enableModal, disableModal, getUserType } from 'common/utilities/utils';
import { updatePaymentAnalytics } from './CheckoutFn';

export default function ProceedToPayment({
  cartData,
  currencyConversionFactor,
  currencyShortName,
  onClose,
  onSubmit,
  productMapById = [{}],
  updateCheckoutDetails,
  checkoutDetails,
}) {
  const { isClickAddPayment, isValid, tender, basketId, tenantId, isValidated, openPaymentPopup } =
    checkoutDetails || {};
  const dispatch = useDispatch();
  const userParams = useSelector(data => data?.appStateReducer?.userStatus);
  const userType = getUserType(userParams?.localized, userParams?.loggedIn);

  const submitBtnClickHandler = () => {
    updateCheckoutDetails?.({
      isClickAddPayment: true,
      isPaymentTypeSelected: true,
      openPaymentPopup: false,
    });
    if (checkoutDetails?.cartData?.paymentGateway === PAYMENT_TENDER_TYPES?.PHONEPE) {
      updateCheckoutDetails({
        paymentEnabled: true,
        showPlaceOrderLoading: false,
      });
    }
    onSubmit && onSubmit();
    updatePaymentAnalytics({ cartData, currencyConversionFactor, tender, productMapById, currencyShortName, userType });
    disableModal();
  };

  const handleClose = () => {
    updateCheckoutDetails?.({
      isClickAddPayment: false,
      isValid: false,
      isValidateBasketActionCompleted: false,
      tender: { name: '', payload: {} },
      openPaymentPopup: false,
    });
    onClose && onClose();
  };

  useEffect(() => {
    if (isClickAddPayment) {
      enableModal?.();
      dispatch(
        validateBasketAction({ data: { id: basketId }, tenantId: tenantId }, response => {
          if (response?.allowedTenders) {
            updateCheckoutDetails?.({
              allowedTenders: response?.allowedTenders?.map(name => name.toLowerCase()),
            });
          }
          updateCheckoutDetails?.({
            isValid: false,
            isValidateBasketActionCompleted: true,
          });
        }),
      );
    }
    return () => {
      disableModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickAddPayment]);

  useEffect(() => {
    switch (tender?.name) {
      case PAYMENT_TENDER_TYPES.NET_BANKING:
        dispatch(getNetbankingConfigurations({ tenantId }));
        break;
      case PAYMENT_TENDER_TYPES.OTHER_WALLET:
        dispatch(getOtherWalletsList({ tenantId }));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tender?.name]);

  useEffect(() => {
    if (isValidated) {
      dispatch(resetValidateBasketAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidated]);

  return (
    <>
      {openPaymentPopup && (
        <div className='proceedpayment-container'>
          <div className={`modal fade ${openPaymentPopup ? 'show' : ''}`} role='dialog'>
            <div className='modal-dialog modal-dialog-scrollable modal-lg'>
              <div className='modal-content' tabindex='-1' aria-labelledby='paymentOptionPopup' role='dialog'>
                <div className='modal-header-parent'>
                  <h2 id='paymentOptionPopup'>{translateWithI18Next('selectYourPaymentScreenHeader')}</h2>
                  <button
                    id='CloseButton'
                    type='button'
                    className='close'
                    onClick={handleClose}
                    data-testid='dialog-conatiner-close'
                    aria-label={translateWithI18Next('closePaymentModal')}
                  >
                    &times;
                  </button>
                </div>
                <div className='modal-body'>
                  <PaymentOptions
                    updateCheckoutDetails={updateCheckoutDetails}
                    checkoutDetails={checkoutDetails}
                    data-testid='payment-opts'
                  >
                    <PaymentForm
                      updateCheckoutDetails={updateCheckoutDetails}
                      checkoutDetails={checkoutDetails}
                      isValid={isValid}
                      tender={tender}
                    />
                  </PaymentOptions>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-dark'
                    data-testid='handle-submit'
                    onClick={submitBtnClickHandler}
                    disabled={!isValid}
                    id='submitButton'
                  >
                    {translateWithI18Next('Submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

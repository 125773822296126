import { createOrderAction } from '@kfc-global/react-shared/redux/Actions/orderAction';
import { fetchCashTransactionIdAction } from '@kfc-global/react-shared/redux/Actions/PostOrderAction';
import {
  getPaytmToken,
  payUsingNetbanking,
  payUsingPhonePe,
  payUsingPhonePePG,
  phonePePGPaymentStatus,
} from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { giftCardAction } from '@kfc-global/react-shared/redux/Actions/PaymentGiftCardAction';
import { PopUpView } from '../../organisms/MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import { initiatePhonepeSwitchTransaction } from '@kfc-global/react-shared/redux/Actions/MicroAppPaymentActions';

import { PAYMENT_TENDER_TYPES } from '../../common/constants/SharedConstants';

export const addToPaymentClickHandler = (
  paymentEnabled,
  isClickAddPayment,
  setIsClickAddPayment,
  setFormPayload,
  userEmail,
  userPhone,
  setTender,
  setIsPaymentTypeSelected,
  setIsClickContinuePayment,
  setOpenPaymentPopup,
  callBackValidateUserDetails,
) => {
  if (paymentEnabled && !isClickAddPayment) {
    setIsClickAddPayment(true);
    setIsClickContinuePayment && setIsClickContinuePayment(false);
    setFormPayload({
      userEmail: userEmail,
      userPhone: userPhone,
    });
    setTender({ name: '', payload: {} });
    setIsPaymentTypeSelected && setIsPaymentTypeSelected(false);
    setOpenPaymentPopup && setOpenPaymentPopup(true);
    PopUpView({ popupTitle: 'SELECT YOUR PAYMENT' });
  } else {
    callBackValidateUserDetails && callBackValidateUserDetails();
  }
};

export const dispatchCreateOrderAction = (dispatch, tender, cartData, createOrderRequest) => {
  createOrderRequest.request.tenders.push({
    type: tender?.name,
    amount: cartData?.total,
  });
  dispatch(createOrderAction(createOrderRequest));
};

export const dispatchCreateOrderActionByCash = (dispatch, tender, orderId, createOrderRequest) => {
  const payload = {
    orderId: orderId,
    tenantId: createOrderRequest?.tenantId,
    customerId: createOrderRequest?.request?.customer?.id,
  };
  dispatch(fetchCashTransactionIdAction(payload));
};

export const dispatchPayUsingNetBankings = param => {
  const { dispatch, tenantId, orderId, tender, formPayload, paymentProvider } = param;
  let requestData = {
    OrderId: orderId,
    TenderType: tender?.name,
    paymentProvider: paymentProvider,
  };
  if (tender?.name === PAYMENT_TENDER_TYPES.NET_BANKING || tender?.name === PAYMENT_TENDER_TYPES.OTHER_WALLET) {
    requestData = {
      ...requestData,
      AdditionalAttributes: {
        BankId: formPayload?.bankId,
      },
    };
  } else if (tender?.name !== PAYMENT_TENDER_TYPES.UPI) {
    formPayload?.svgSource?.['aria-label'] === PAYMENT_TENDER_TYPES.AMERICAN_EXPRESS &&
      (requestData = {
        ...requestData,
        AdditionalAttributes: {
          BankID: 'AmExID',
        },
      });
  }

  orderId &&
    dispatch(
      payUsingNetbanking({
        tenantId,
        data: requestData,
      }),
    );
};

export const dispatchPayUsingPaytm = (dispatch, tenantId, orderId, tender) => {
  const payload = {
    OrderId: orderId,
    TenderType: tender?.name,
  };

  dispatch(
    getPaytmToken({
      tenantId,
      data: payload,
    }),
  );
};

export const dispatchGiftCardAction = (dispatch, tenantId, orderId, tender, formPayload, profileReducer) => {
  //remove extra keys from payload
  delete formPayload?.userEmail;
  delete formPayload?.userPhone;

  let customerId = profileReducer.customerId;

  let reqPayload = {
    OrderId: orderId,
    TenderType: tender?.name,
    ...formPayload,
  };

  dispatch(
    giftCardAction({
      tenantId,
      customerId,
      body: reqPayload,
    }),
  );
};

export const dispatchPayUsingPhonePe = params => {
  const { dispatch, tenantId, orderId, tender, profileReducer } = params;
  const payload = {
    orderId: orderId,
    tenderType: tender?.name,
    gateway: PAYMENT_TENDER_TYPES.PHONEPEPG,
  };

  let customerId = profileReducer?.customerId;

  dispatch(
    payUsingPhonePePG({
      tenantId,
      customerId,
      data: payload,
    }),
  );
};

export const dispatchPhonePePGPaymentStatus = params => {
  const { dispatch, tenantId, data, customerId } = params;
  const payload = {
    ...data,
  };

  dispatch(
    phonePePGPaymentStatus({
      tenantId,
      customerId,
      data: payload,
    }),
  );
};

export const dispatchPayUsingPhonepeSwitch = (
  tenantId,
  orderId,
  selectedTender,
  basketData,
  dispatch,
  setGpayPaymentValidity,
) => {
  const payload = {
    tenantId,
    data: {
      OrderId: orderId,
      TenderType: selectedTender,
    },
    customerId: basketData?.customer?.id || '',
  };
  orderId && dispatch(initiatePhonepeSwitchTransaction(payload));
  setGpayPaymentValidity(true);
};

import React from 'react';
import { MaterialTextInput } from 'atoms/MaterialInput';
import { ButtonType, ContainedButton } from 'atoms/Buttons';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { getPlaceHolderText, validateAddHopeForm, getClassNameforAddTipButton } from 'common/utilities/utils';
import PropTypes from 'prop-types';
import { getToastNotification } from 'common/sharedTemplates/toasterTemplates';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { FUNCTION_TYPE } from 'common/constants/SharedConstants';

export const handleFocusCall = (event, props) => {
  const { handleFocus } = props;

  handleFocus(props);
};

export const handleBlurCall = (event, props) => {
  const { handleBlur } = props;

  props?.donationsButtonData.customAmount === '' && handleBlur(event, props);
};

export const getMaxLength = isAddHopeFlow => {
  return isAddHopeFlow ? 7 : 10;
};

export const getCustomAmount = (isAddHopeFlow, customAmountTip, customAmount) => {
  return isAddHopeFlow ? customAmountTip : customAmount;
};

export const getAddHopeDonationClearHandler = (isAddHopeFlow, setCustomAmountTip) => {
  return isAddHopeFlow && setCustomAmountTip('');
};

export const getAddHopeChangeHandler = (event, isAddHopeFlow, setCustomAmountTip) => {
  return isAddHopeFlow && setCustomAmountTip(event.target.value);
};

export const getAddHopeDefaultAmountSelect = (isAddHopeFlow, setCustomAmountTip, data, currencySymbol) => {
  return isAddHopeFlow && setCustomAmountTip(data.displayNonPercentageAmount.split(currencySymbol).join(''));
};
export const getTippingTileClass = tilesClassValue =>
  tilesClassValue ? tilesClassValue : 'tipping-details px-25px col-sm-8 col-12';
const DonationButtonGroup = props => {
  const {
    donationChargeOption,
    showNonDonationPercentage,
    showDonationsCustomFields,
    inputFiledValidErrormsg,
    customAmount,
    addDonationBtnHandler,
    donationRegexPattern,
    editButtonShow,
    buttonText,
    showCurrencyField,
    isAddHopeFlow,
    showImageField,
    showOthersCaluclatedValue,
    othersCaluclatedValue,
    inputTextPlaceHolder,
  } = props?.donationsButtonData;
  const {
    onClickDonationHandler,
    onClickDonationAddHandler,
    onChangeInputHandler,
    onClearDonationTextHandler,
    onEditDonationTextHandler,
    currencySymbol,
    shortDesc,
    title,
    manualEditButtonTitle,
    isFormValid,
    cartSubTotal,
    tilesClassValue,
    ImageSelectedForInput,
    showAddHopeModalView = false,
  } = props;

  const [customAmountTip, setCustomAmountTip] = React.useState(null);

  const donationItemSelected = (data, index) => {
    const tipExceed = data?.value > cartSubTotal;
    !tipExceed && getAddHopeDefaultAmountSelect(isAddHopeFlow, setCustomAmountTip, data, currencySymbol);
    !tipExceed && onClickDonationHandler(data, index, props);
    tipExceed && getToastNotification(translateWithI18Next(TRANSLATE_MAPPING_KEY.TIP_EXCEED_SUBTOTAL));
  };

  const addDonationButtonHandler = () => {
    onClickDonationAddHandler(props);
  };

  const inputOnChangeHandler = (event, isAddHopeFlowInfo, setCustomAmountTipInfo) => {
    const amountValid = donationRegexPattern.test(String(event.target.value).toLowerCase());

    if (amountValid) {
      getAddHopeChangeHandler(event, isAddHopeFlowInfo, setCustomAmountTipInfo);

      onChangeInputHandler(event, props);
    }
  };

  const onClearDonationHandler = (isAddHopeFlowInfo, setCustomAmountTipInfo) => {
    getAddHopeDonationClearHandler(isAddHopeFlowInfo, setCustomAmountTipInfo);

    onClearDonationTextHandler(props);
  };

  const onEditDonationHandler = () => {
    onEditDonationTextHandler(props);
  };

  const getTitle = () => (typeof title === FUNCTION_TYPE ? title?.() : title);

  const getShortDesc = () => (typeof shortDesc === FUNCTION_TYPE ? shortDesc?.() : shortDesc);

  const getManualEditButtonTitle = () =>
    typeof manualEditButtonTitle === FUNCTION_TYPE ? manualEditButtonTitle?.() : manualEditButtonTitle;
  const notNowOptionIndex = donationChargeOption.findIndex(option => option.name === 'Not Now');
  return (
    <div className='checkoutContactInfoContainer'>
      <div className='row row-cols-12 mt-10px donationbutton'>
        <div className='labelContent info-label pl-25px col-sm-4 col-12'>
          <span>{getShortDesc()}</span>
        </div>

        <div className={getTippingTileClass(tilesClassValue)}>
          <div className='tip-text'>{getTitle()}</div>

          <div className='flex-container tiles-main-container'>
            {donationChargeOption &&
              donationChargeOption?.map((data, index) => {
                return (
                  <div
                    data-testid='sort-visit-change'
                    className={data?.isDefault ? 'selected-tip' : ''}
                    onClick={() => donationItemSelected(data, index)}
                  >
                    {data?.displayChargeAmount && (
                      <p className={`tippingtext ${data?.containerClassName}`}>{data?.displayChargeAmount}</p>
                    )}
                    {showNonDonationPercentage && (
                      <p
                        className={`${data?.value > cartSubTotal ? 'enbledtippingpercentage' : 'tippingpercentage'} ${
                          data?.containerClassName
                        }`}
                      >
                        {data?.displayNonPercentageAmount}
                      </p>
                    )}
                  </div>
                );
              })}
          </div>

          {showDonationsCustomFields && (
            <>
              <div className='deliveryinputfield'>
                <MaterialTextInput
                  className={`formElement`}
                  placeholder={getPlaceHolderText(isAddHopeFlow, inputTextPlaceHolder)}
                  onChange={event => inputOnChangeHandler(event, isAddHopeFlow, setCustomAmountTip)}
                  value={getCustomAmount(isAddHopeFlow, customAmountTip, customAmount)}
                  autocomplete='off'
                  maxLength={getMaxLength(isAddHopeFlow)}
                  inputTestId={`tipinputtestfield`}
                  disabled={!addDonationBtnHandler}
                  showCurrencySymbol={showCurrencyField}
                  onFocus={event => handleFocusCall(event, props)}
                  onBlur={event => handleBlurCall(event, props)}
                  currencySymbol={currencySymbol}
                  readOnly={props.donationsButtonData?.readOnly}
                  showImageField={showImageField}
                  ImageSelectedForInput={ImageSelectedForInput}
                />

                {customAmount && addDonationBtnHandler && (
                  <img
                    src={IMAGE_PATH?.clearTextIcon}
                    alt='clear-text-btn'
                    data-testid='clear-text-btn'
                    className='cleariconsection'
                    onClick={() => donationItemSelected(donationChargeOption[notNowOptionIndex], notNowOptionIndex)}
                  />
                )}

                {customAmount && !addDonationBtnHandler && (
                  <div data-testid='edit-btn' className='editsection' onClick={() => onEditDonationHandler()}>
                    {getManualEditButtonTitle()}
                  </div>
                )}
              </div>

              {inputFiledValidErrormsg !== '' && (
                <div className='alert-warning'>
                  {<img src={IMAGE_PATH?.Ellipse} alt='error' classname={`mr-2`} />}

                  <span className={`ml-2  ${isAddHopeFlow ? 'form-error' : 'alert-warningtext'}`} role='alert'>
                    {inputFiledValidErrormsg}
                  </span>
                </div>
              )}
              {showOthersCaluclatedValue && (
                <div className='col-12 addhope-amount'>{`${currencySymbol} ${othersCaluclatedValue}`}</div>
              )}
              {!editButtonShow && (
                <div
                  className={`${showAddHopeModalView ? 'col-9' : 'col-lg-12'} center-element mt-20px txt-align-center`}
                >
                  <ContainedButton
                    className={getClassNameforAddTipButton({
                      isAddHopeFlow,

                      addDonationBtnHandler,

                      inputFiledValidErrormsg,

                      donationsButtonData: props.donationsButtonData,

                      isValid: isFormValid,
                    })}
                    variant={ButtonType?.primary}
                    onClick={() => addDonationButtonHandler()}
                    data-testid='add-drivertip-button'
                    inactive={
                      !isAddHopeFlow
                        ? !addDonationBtnHandler
                        : !validateAddHopeForm(props.donationsButtonData, isFormValid)
                    }
                  >
                    {buttonText}
                  </ContainedButton>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DonationButtonGroup.propTypes = {
  donationsButtonData: {
    donationChargeOption: PropTypes.array,
    showNonDonationPercentage: PropTypes.bool,
    showDonationsCustomFields: PropTypes.bool,
    addDonationBtnHandler: PropTypes.bool,
    inputFiledValidErrormsg: PropTypes.string,
    customAmount: PropTypes.string,
  },

  setFormFormFeildsData: PropTypes.func,
  onClickDonationHandler: PropTypes.func,
  onClickDonationAddHandler: PropTypes.func,
  onChangeInputHandler: PropTypes.func,
  onClearDonationTextHandler: PropTypes.func,
  onEditDonationTextHandler: PropTypes.func,
  currencySymbol: PropTypes.string,
};

DonationButtonGroup.defaultProps = {
  formFormFeildsData: {
    donationChargeOption: [],
    showNonDonationPercentage: false,
    showDonationsCustomFields: false,
    addDonationBtnHandler: false,
    inputFiledValidErrormsg: '',
    customAmount: '',
  },

  currencySymbol: '',
};

export default DonationButtonGroup;

import React from 'react';
import LazyLoaderWrapper from 'atoms/LazyLoaderWrapper';
import { ROUTE_URL } from 'common/constants/SharedConstants';

const getDynamicHeaderFooter = props => {
  const pages = [
    ROUTE_URL.MENU_PAGE,
    ROUTE_URL.OFFERS,
    ROUTE_URL.CART,
    ROUTE_URL.CHECKOUT,
    ROUTE_URL.ORDER_PROCESSING,
    ROUTE_URL.PAYMENT_FAILURE,
    ROUTE_URL.ERROR_SCREEN,
  ];
  const rootHeaderContainerPath =
    pages.includes(window.location.pathname) || window.location.pathname.includes(`${ROUTE_URL.MENU_PAGE}/`)
      ? 'GLOBAL_ROOT_HEADER_CONTAINER'
      : 'ROOT_HEADER_CONTAINER';
  return (
    <LazyLoaderWrapper
      {...props}
      testId='root-header-container'
      importPath={rootHeaderContainerPath}
    ></LazyLoaderWrapper>
  );
};

const HeaderFooterSwitch = props => {
  return getDynamicHeaderFooter(props);
};

export default HeaderFooterSwitch;

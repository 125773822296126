import React, { useEffect } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib/i18N';

import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import ImageComponent from 'atoms/Image/index';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { PopUpView } from '../../../organisms/MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import { ContainedButton } from 'atoms/Buttons';

import { cleanStringList } from 'common/utilities/StringUtils';
import { isSupportedTenantCode } from 'common/utilities/utils';

/**
 * Modal Close Button Component
 * @param {*} props - Object
 */
const ModalButton = props => {
  const {
    ariaLabel = 'Back',
    clickHandler,
    closeBtnRefCallback,
    alt = 'Close OFFERS & DEALS dialog',
    className = props.ariaLabel === 'Back' ? 'custom-modal-close' : 'custom-modal-close custom-modal-close-focus',
    imageSrc = '',
    dataTestId,
  } = props;
  return (
    <button
      type='button'
      className={className}
      data-dismiss='modal'
      aria-label={ariaLabel}
      onClick={clickHandler}
      ref={closeBtnRefCallback}
      data-testid={dataTestId}
    >
      <ImageComponent srcFile={imageSrc} alt={alt} />
    </button>
  );
};

/**
 * Modal Popup Items Components
 * @param {*} props - Object
 */
const ModalCompItemsComponent = props => {
  const {
    id = '',
    classNames,
    show,
    autoFocus,
    modalTitleId,
    modalTitle = null,
    children,
    closeBtnRefCallback,
    showCloseBtn = false,
    showBackBtn = false,
    closeButtonHandler,
    backButtonHandler,
    ariaLabel,
    modalTitleRequired = true,
    backBtnClassName = 'custom-modal-back',
    backBtnAlt = 'back',
    closeDataTestId = '',
    backDataTestId = '',
    showFooterSection = false,
    buttonVariant,
    footerBtnText = null,
    footerBtnHandler,
    footerBtnTestId,
    footerLinkText = null,
    footerLink,
    footerLinkTestId,
    disabledBtn = false,
    loadingBtn = false,
  } = props;
  const { PopupCloseBtn, ArrowIcon } = IMAGE_PATH;
  const { AUTO, HIDDEN } = START_ORDER_CONSTANTS;
  useEffect(() => {
    document.querySelector('body').style.overflow = HIDDEN;
    return () => {
      document.querySelector('body').style.overflow = AUTO;
    };
  });
  useEffect(() => {
    if (modalTitle) {
      PopUpView({ popupTitle: modalTitle });
    }
  }, [modalTitle]);

  return (
    <div
      id={id}
      show={show.toString()}
      className={`shared-modal-container ${isSupportedTenantCode() && 'au-modal-container'} ${classNames}`}
      autoFocus={autoFocus || false}
      data-testid={modalTitleId}
    >
      <div className='modal-content' tabIndex='-1' aria-labelledby={modalTitleId} role='dialog'>
        <div className='modal-header'>
          {showBackBtn && backButtonHandler && (
            <ModalButton
              ariaLabel='Go back to Start Your Order dialog'
              clickHandler={backButtonHandler}
              className={backBtnClassName}
              imageSrc={ArrowIcon}
              alt={backBtnAlt}
              dataTestId={backDataTestId}
            />
          )}
          {modalTitleRequired && (
            <h2 className='header-title' id={modalTitleId} data-testid='view-modal-title'>
              {translateWithI18Next(modalTitle)}
            </h2>
          )}
          {showCloseBtn && closeButtonHandler && (
            <ModalButton
              ariaLabel={ariaLabel}
              clickHandler={closeButtonHandler}
              closeBtnRefCallback={closeBtnRefCallback}
              imageSrc={PopupCloseBtn}
              dataTestId={closeDataTestId}
            />
          )}
        </div>
        <div className='modal-body' tabIndex='-1'>
          {children}
        </div>
        {showFooterSection && (
          <div className='modal-footer'>
            {(footerBtnTestId || footerBtnText) && (
              <div className='button-container'>
                <ContainedButton
                  variant={buttonVariant}
                  aria-label={footerBtnText}
                  onClickHandler={footerBtnHandler}
                  data-testid={footerBtnTestId}
                  inactive={disabledBtn}
                  loading={loadingBtn}
                >
                  {translateWithI18Next(footerBtnText)}
                </ContainedButton>
              </div>
            )}
            {footerLink && footerLinkText && <a href={footerLink}>{translateWithI18Next(footerLinkText)}</a>}
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Modal Popup Item Component
 * @param {*} props - Object
 */
const ModalCompItems = props => <ModalCompItemsComponent {...props} />;

export default ModalCompItems;

import {
  getContentCall,
  getFindAKFCContentData,
  getGuestContentData,
} from '@kfc-global/react-shared/redux/Actions/ContentAction';
import getContentFaqCall from '@kfc-global/react-shared/redux/Actions/FaqActions';
import getMarketConfigContentCall from '@kfc-global/react-shared/redux/Actions/MarketConfigAction';
import { getTendersContent } from '@kfc-global/react-shared/redux/Actions/PaymentsContentAction';
import { ChannelManager } from 'common/manager';
import { getCmsClient } from './cmsClient';
import getContentPlpDisclaimer from '@kfc-global/react-shared/redux/Actions/PLPDisclaimerAction';
import { contentfulActions } from '@kfc-global/react-shared/redux/Slices/ContentfulSlice';
import getContentDeliveryAggregatorAction from '@kfc-global/react-shared/redux/Actions/DeliveryAggregatorAction';
import { getDeliveryLocationLocalization } from '@kfc-global/react-shared/redux/Actions/StoresAction';

export const getFaqContent = () =>
  getContentFaqCall({
    object: getCmsClient(),
    contentFull: {
      content_type: 'staticLandingPage',
      'fields.page': 'FAQ',
      include: '5',
    },
  });

export const getMarketConfigContent = tenantReducer =>
  getMarketConfigContentCall({
    object: getCmsClient(),
    contentFull: {
      content_type: 'marketConfig',
      'fields.location': tenantReducer?.country,
      'fields.channel': ChannelManager.channel,
      include: 5,
    },
  });

export const getContent = userParams =>
  getContentCall({
    object: getCmsClient(),
    contentFull: {
      content_type: 'landingPage',
      'fields.pageType': 'HomePage',
      'fields.channel': ChannelManager.channel,
      include: '5',
    },
    userParams,
  });

export const getTenders = () =>
  getTendersContent({
    object: getCmsClient(),
    contentFull: {
      content_type: 'tenders',
      include: 5,
      select: 'sys.id,fields',
    },
  });

export const getGuestContent = () =>
  getGuestContentData({
    contentful: getCmsClient(),
    query: {
      content_type: 'form',
      'fields.page': 'GuestCheckOut',
      'fields.channel': ChannelManager.channel,
      include: '5',
    },
  });

export const getFindAKFCContent = () =>
  getFindAKFCContentData({
    object: getCmsClient(),
    contentFull: {
      content_type: 'staticLandingPage',
      'fields.page': 'FindAKFC',
      include: '5',
    },
  });

export const getTimeSpecificAlertContent = () =>
  getContentPlpDisclaimer({
    object: getCmsClient(),
    contentFull: {
      content_type: 'supportContent',
      'fields.title': 'Alerts',
      include: '5',
    },
  });
export const getAddHopeThankYouContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'supportContent',
      include: '5',
      'fields.title': 'addHopeThankYou',
    },
  });

export const getAddHopeContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'staticLandingPage',
      'fields.page': 'addHope',
      include: '5',
    },
  });

export const getTermsAndConditionsContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'brandPage',
      'fields.url': '/rakuten-terms-condition',
      include: '5',
      locale: 'ja-JP',
    },
  });
export const getOrderSummaryContent = service =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'supportContent',
      'fields.title': 'OrderTracking',
      include: '5',
      'fields.service': `${service}`,
    },
  });

export const getDeliveryAggregatorContent = () =>
  getContentDeliveryAggregatorAction({
    object: getCmsClient(),
    contentFull: {
      content_type: 'deliveryAggregatorServices',
      include: '1',
    },
  });

export const getGiftCardContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'staticLandingPage',
      'fields.title': 'GiftCard',
      include: '5',
    },
  });

/**
 * @function getDeliveryLocalisationContent - Perform the request to get the Contentful data for localisation flow
 * @param {String} searchType -Specific delivery search type, it could be address tab or postal code
 */
export const getDeliveryLocalisationContent = searchType =>
  getDeliveryLocationLocalization({
    object: getCmsClient(),
    contentFull: {
      content_type: 'deliveryLocalization',
      'fields.slug': searchType,
      include: '1',
      select: 'fields.data',
    },
  });

export const getFreeDeliveryContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'cartPageAdditionalText',
      'fields.title': 'Charge',
      include: '5',
      locale: 'en-US',
    },
  });

export const getFreeDeliveryContentData = fields => {
  return {
    amount: fields?.amount,
    line: fields?.line,
    value: fields?.strikethroughValue,
  };
};

export const getUberEatsAlertContent = () =>
  contentfulActions.fetch({
    contentful: getCmsClient(),
    query: {
      content_type: 'supportContent',
      'fields.title': 'UberEatsAlert',
    },
  });

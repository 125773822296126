import React, { useEffect, useState } from 'react';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { updateNewAddressHandler } from 'common/utilities/PaymentUtils';
import { BillingAddressTemplate } from './BillingAddressTemplate';
import PaymentSaveCardTemplate from './PaymentSaveCardTemplate';
import { isPickupDisposition } from 'common/utilities/utils';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import { useSelector } from 'react-redux';

export const checkFormValidationBillingformValidation = addresses =>
  addresses?.addressLine?.length && addresses?.pincode?.length;

const PaymentBillingAndSavingCardTemplate = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;

  const { billingAddressAndSaveCard } = checkoutDetails;
  const [showResults, setShowResults] = useState(true);
  const [addresses, setSaveAddress] = useState({ addressLine: '', pincode: '' });
  const { GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS } = PAYMENT_CONSTANTS;
  const applyPostalCodeRules = useSelector(
    data => data.tenantReducer?.componentOptions?.checkout?.applyPostalCodeRules,
  );

  const handleInputBilling = event => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (applyPostalCodeRules && name === GLOBAL_PAYMENTS_ADDRESS_VERIFICATION_FIELDS?.PINCODE) {
      formattedValue = formattedValue.toUpperCase();
      if (formattedValue.length > 3 && formattedValue[3] !== ' ') {
        formattedValue = formattedValue.slice(0, 3) + ' ' + formattedValue.slice(3);
      }
      if (formattedValue.length > 7) {
        formattedValue = formattedValue.slice(0, 7);
      }
    }
    setSaveAddress({ ...addresses, [name]: formattedValue });
    updateCheckoutDetails({
      [name]: formattedValue,
    });
  };

  useEffect(() => {
    updateCheckoutDetails({
      billingAddressAndSaveCard: {
        ...billingAddressAndSaveCard,
        addressLine: addresses?.addressLine,
        pincode: addresses?.pincode,
        isBillingAddressFormDetailsValid: checkFormValidationBillingformValidation(addresses),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  return (
    <>
      <div className='savebilling-address'>
        {!isPickupDisposition(checkoutDetails?.service) && (
          <div
            className={` ${
              checkoutDetails?.isAddNewCard ? 'hidden' : 'd-flex'
            } form-checkbox-container form-checkbox-payonarrival`}
          >
            <input
              type='checkbox'
              className={`common_checkbox`}
              onChange={event =>
                updateNewAddressHandler({ event, checkoutDetails, updateCheckoutDetails, setShowResults })
              }
              data-testid={`Address-default-checkbox`}
              checked={showResults}
              name='cc-Payment-billing-address'
              id='Payment-billing-address'
              disabled={checkoutDetails?.service === 'pickup'}
              defaultChecked={checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected}
            />
            <label title='title' className='form-check-label' for='Payment-billing-address'>
              {translateWithI18Next(TRANSLATE_MAPPING_KEY?.USE_DELIVARY_ADDRESS)}
            </label>
          </div>
        )}
        {(!showResults || isPickupDisposition(checkoutDetails?.service) || checkoutDetails?.isAddNewCard) && (
          <>
            <BillingAddressTemplate
              data-testid='billing-address'
              handleInputBilling={handleInputBilling}
              addresses={addresses}
              checkoutDetails={checkoutDetails}
              updateCheckoutDetails={updateCheckoutDetails}
            />
          </>
        )}
        {(checkoutDetails?.isAddNewCard || isPickupDisposition(checkoutDetails?.service) || !showResults) && (
          <div className='required-suggestion'>{translateWithI18Next(TRANSLATE_MAPPING_KEY?.MANDATORY_TEXT)}</div>
        )}
        {(checkoutDetails?.userParams?.loggedIn || checkoutDetails?.isAddNewCard) && (
          <PaymentSaveCardTemplate {...props} />
        )}
      </div>
    </>
  );
};

export default React.memo(PaymentBillingAndSavingCardTemplate);
